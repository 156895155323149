import {inject, observer} from 'mobx-react';
import React, {Component} from 'react';
import ReactModal from 'react-modal';

import {ReactComponent as Close} from '../../../img/icon-close.svg';

ReactModal.setAppElement(document.body);

@inject('rootStore')
@observer
export class CardPreview extends Component {
  render() {
    const {isOpen, closeModal, params, rootStore} = this.props;
    const {session = {}} = rootStore.sessionWsStore;
    return (
      <ReactModal
        overlayClassName='modal-dark-overlay'
        className={`card-preview-modal ${session.trialMode ? 'top-align' : ''}`}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        isOpen={isOpen}
      >
        <div>
          <Close onClick={closeModal} className='icon-close' />
          <img src={params.url} alt='card-preview' />
        </div>
      </ReactModal>
    );
  }
}
