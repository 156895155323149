import React, {Component} from 'react';
import Tabs, {TabPane} from 'rc-tabs';
import {inject, observer} from 'mobx-react';

import {Card} from '../../helpers/card/Card';
import {translate} from '../../../utils/translater';
import {DeckPanelControls} from './DeckPanelControls';

import {ReactComponent as ArrowIcon} from '../../../img/arrow.svg';

@inject('rootStore')
@observer
export class CardsPanel extends Component {
  state = {
    activeTab: '0',
  };

  toggleTab = (increase) => {
    let currentTab = Number(this.state.activeTab);
    const {media} = this.props.rootStore.sessionWsStore;

    if (increase && currentTab < media.length) {
      currentTab += 1;
      this.setState({activeTab: `${currentTab}`});
    } else if (!increase && currentTab > 0) {
      currentTab -= 1;
      this.setState({activeTab: `${currentTab}`});
    }
  };

  onTabClick = (key) => {
    this.setState({activeTab: key});
  };

  barControls = () => ({
    left: (
      <div onClick={this.toggleTab.bind(this, false)} className='arrow__left'>
        <ArrowIcon className='arrow__pic' />
      </div>
    ),
    right: (
      <div onClick={this.toggleTab.bind(this, true)} className='arrow__right'>
        <ArrowIcon className='arrow__pic' />
      </div>
    ),
  });

  selectCard = (folder, file, shirt) => {
    const {selectCard} = this.props.rootStore.sessionWsStore;
    selectCard(folder, file, shirt);
  };

  cardIsSelected = (id) => {
    const {session} = this.props.rootStore.sessionWsStore;
    const result = session.activeSelections.findIndex((card) => card.id === id);
    return result >= 0;
  };

  renderCards = (file, deck) => {
    const {canSelectCard, localScale, session} = this.props.rootStore.sessionWsStore;
    return this.cardIsSelected(file.id) ? null : (
      <div key={file.id} className='cards-list__item'>
        <Card
          scale={localScale}
          isTrialMode={session.trialMode}
          canSelectCard={canSelectCard()}
          isFlipped={deck.isFlipped}
          cardShirt={deck.deckShirt}
          originalHeight={file.originalHeight}
          originalWidth={file.originalWidth}
          deckIsHorizontal={deck.isHorizontal}
          cardFaceImg={file.url}
          cardIsHorizontal={file.isHorizontal}
          animationIsActive={file.animationIsActive}
          onDoubleClick={this.selectCard.bind(this, deck.folder, file, deck.deckShirt)}
        />
      </div>
    );
  };

  render() {
    const {activeTab} = this.state;
    const {sessionWsStore, userStore} = this.props.rootStore;
    const {media} = sessionWsStore;
    return (
      <div className='card-tabs'>
        <Tabs
          onTabClick={this.onTabClick}
          activeKey={activeTab}
          moreIcon={false}
          tabBarExtraContent={this.barControls()}
        >
          <TabPane tab={translate('Все колоды')} key='0'>
            <div className='card-tabs__btns-wrapper'>
              <DeckPanelControls isAdmin={userStore.user.isAdmin} />
            </div>
            <div className='cards-list'>
              {media.map((deck) => (deck.files || []).map((file) => this.renderCards(file, deck)))}
            </div>
          </TabPane>
          {media.map((deck, i) => (
            <TabPane tab={deck.name} key={`${i + 1}`}>
              <div className='card-tabs__btns-wrapper'>
                <DeckPanelControls deck={deck} isAdmin={userStore.user.isAdmin} />
              </div>
              <div className='cards-list'>{(deck.files || []).map((file) => this.renderCards(file, deck))}</div>
            </TabPane>
          ))}
        </Tabs>
      </div>
    );
  }
}
