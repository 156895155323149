import React, {Component} from 'react';
import {ReactComponent as Error} from '../../../img/app-error.svg';

export class AppError extends Component {
  render() {
    return (
      <div className='app-error'>
        <Error />
        <p>Application error.</p>
        <p>Something has gone wrong.</p>
        <p>We are trying to solve the problem.</p>
      </div>
    );
  }
}
