import {inject} from 'mobx-react';
import React, {Component} from 'react';

import {Link} from 'react-router-dom';
import {Input} from '../../helpers/input/Input';
import {Button} from '../../helpers/button/Button';
import {I18n} from '../../helpers/i18n/I18n';

@inject('rootStore')
export class ResetPassword extends Component {
  state = {
    email: '',
    error: '',
    isFetching: false,
  };

  inputChange = (prop, val) => {
    this.setState({[prop]: val});
  };

  reset = () => {
    const {email} = this.state;
    if (!email) {
      this.setState({error: 'Email is empty'});
    } else {
      this.setState({isFetching: true});
      const {userStore} = this.props.rootStore;
      userStore.resetPassword(email).then((r) => {
        console.log(r);
        const newState = {isFetching: false, error: false};
        window.location.href = 'https://cards.mac-online.ru/';
        this.setState(newState);
      });
    }
  };

  render() {
    const {email, error, isFetching} = this.state;
    return (
      <div className='auth-page'>
        <div className='auth-page__content'>
          <div className='auth-form__input'>
            {' '}
            <Input label='auth.registration.email' onChange={this.inputChange} name='email' value={email} />
          </div>
          <div className='auth-page__actions auth-actions'>
            <div className='auth-actions__item'>
              <Button altTheme onClick={this.reset} disabled={isFetching} label='auth.btn.reset' />
            </div>
            <div className='auth-actions__item'>
              <Link to='/auth'>
                <I18n tKey='auth.btn.login' />
              </Link>
            </div>
          </div>
          {error && (
            <div className='auth-actions__msg'>
              <I18n className='warning-txt' tKey={error} />
            </div>
          )}
        </div>
        <div />
      </div>
    );
  }
}
