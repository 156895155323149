import React, {Component} from 'react';
import classNames from 'classnames';
import {I18n} from '../i18n/I18n';

export class CheckBox extends Component {
  onChange = (ev) => {
    this.props.onChange && this.props.onChange(ev.target.name, this.props.value);
  };

  render() {
    const {isChecked, name, value, label} = this.props;
    const checkboxClassParams = {
      'checkbox__fake--checked': isChecked,
      'checkbox__fake--offset': !!label,
      'checkbox__fake--default-size': true,
    };
    return (
      <label className='checkbox'>
        <input
          className='checkbox__input'
          type='checkbox'
          onChange={this.onChange}
          value={value}
          name={name}
          checked={isChecked}
        />
        <span className={classNames('checkbox__fake', checkboxClassParams)} />
        {label && <I18n tKey={label} className='checkbox__label' />}
      </label>
    );
  }
}
