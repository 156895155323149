import {inject} from 'mobx-react';
import React, {Component} from 'react';

import {Link} from 'react-router-dom';
import {Input} from '../../helpers/input/Input';
import {Button} from '../../helpers/button/Button';
import {I18n} from '../../helpers/i18n/I18n';

@inject('rootStore')
export class Registration extends Component {
  state = {
    email: '',
    password: '',
    confirmPassword: '',
    name: '',
    error: '',
    isFetching: false,
  };

  inputChange = (prop, val) => {
    this.setState({[prop]: val});
  };

  register = () => {
    const {email, name, password, confirmPassword} = this.state;
    if (!password) {
      this.setState({error: 'Empty password'});
    } else if (password !== confirmPassword) {
      this.setState({error: 'Passwords isnt equal'});
    } else if (!email) {
      this.setState({error: 'Email is empty'});
    } else if (!name) {
      this.setState({error: 'Name is empty'});
    } else {
      this.setState({isFetching: true});
      const {userStore} = this.props.rootStore;
      userStore.register(email, name, password).then((r) => {
        const newState = {isFetching: false, error: false};
        if (r.isError) {
          if (r.data.message === 'UNVERIFIED_USER_EMAIL') {
            window.location.href = 'https://mac-online.ru/successfull_registration/';
          } else {
            newState.error = r.data.message;
          }
        }
        this.setState(newState);
      });
    }
  };

  render() {
    const {email, name, password, confirmPassword, error, isFetching} = this.state;
    return (
      <div className='auth-page'>
        <div className='auth-page__content'>
          <div className='auth-form__input'>
            <Input label='auth.registration.name' onChange={this.inputChange} name='name' value={name} />
          </div>
          <div className='auth-form__input'>
            {' '}
            <Input label='auth.registration.email' onChange={this.inputChange} name='email' value={email} />
          </div>
          <div className='auth-form__input'>
            {' '}
            <Input
              isPassword
              showEye
              label='auth.registration.password'
              onChange={this.inputChange}
              name='password'
              value={password}
            />
          </div>
          <div className='auth-form__input'>
            {' '}
            <Input
              isPassword
              showEye
              label='auth.registration.confirmPassword'
              onChange={this.inputChange}
              name='confirmPassword'
              value={confirmPassword}
            />
          </div>
          <div className='auth-page__actions auth-actions'>
            <div className='auth-actions__item'>
              <Button altTheme onClick={this.register} disabled={isFetching} label='auth.btn.register' />
            </div>
            <div className='auth-actions__item'>
              <Link to='/auth'>
                <I18n tKey='auth.registration.toLogin' />
              </Link>
            </div>
          </div>
          {error && (
            <div className='auth-actions__msg'>
              <I18n className='warning-txt' tKey={error} />
            </div>
          )}
        </div>
        <div />
      </div>
    );
  }
}
