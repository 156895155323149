import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {withRouter} from 'react-router';
import classNames from 'classnames';

// import {NavLink} from 'react-router-dom';

import {I18n} from '../../helpers/i18n/I18n';
import {Button} from '../../helpers/button/Button';

import {ReactComponent as LogoIcon} from '../../../img/logoTxt.svg';
import {ReactComponent as PlusIcon} from '../../../img/plus.svg';
import {ReactComponent as CrossIcon} from '../../../img/cross.svg';
// import {ReactComponent as UploadIcon} from '../../../img/upload.svg';
// import {ReactComponent as VideoIcon} from '../../../img/video.svg';
import {ReactComponent as UserIcon} from '../../../img/user.svg';

@withRouter
@inject('rootStore')
@observer
export class Header extends Component {
  createSession = () => {
    this.props.history.push('/constructor');
  };

  redirectToHome = () => {
    this.props.history.push('/');
  };

  render() {
    const {rootStore} = this.props;
    const {userStore, sessionWsStore} = rootStore;
    const {session} = sessionWsStore;
    const hideBtns = this.props.location.pathname === '/constructor';
    const isSessionListPage = this.props.location.pathname === '/';
    return (
      <header className='header'>
        <div className='header__logo' onClick={this.redirectToHome}>
          <LogoIcon className='header__logo-pic' />
          {/* <I18n tKey='header.title' /> */}
        </div>
        {session && (
          <div className='header__info-block session-info-block'>
            <div className='session-info-block__item'>{session.sessionName}</div>
            <div className='session-info-block__item'>
              <I18n tKey='common.online' />: {(session.activeConnections || []).length}
            </div>
            {/* <div className='session-info-block__item'> */}
            {/*  <I18n tKey='Duration: 30 mins' /> /!* // TODO *!/ */}
            {/* </div> */}
          </div>
        )}
        <div className={classNames('header__buttons', {'header__buttons--hidden': hideBtns})}>
          {isSessionListPage && (
            <>
              {/* <div className='header__buttons-item'> */}
              {/*  <Button BtnPic={UploadIcon} label='header.link.upload' /> */}
              {/* </div> */}
              <div className='header__buttons-item'>
                <Button BtnPic={PlusIcon} label='header.link.create' onClick={this.createSession} altTheme />
              </div>
            </>
          )}
          {/* <div className='header__buttons-item'><Button BtnPic={CrossIcon} label='header.link.end'/></div> */}{' '}
          {/* <---End Session Button */}
          {session && (
            <>
              {/* <div className='header__buttons-item'> */}
              {/*  <Button BtnPic={VideoIcon} label='header.link.start_video' /> */}
              {/* </div> */}
              {/* <div className='header__buttons-item'> */}
              {/*  <Button BtnPic={PlusIcon} label='header.link.invite_clients' /> */}
              {/* </div> */}
              <div className='header__buttons-item'>
                <Button onClick={this.redirectToHome} BtnPic={CrossIcon} label='header.btn.close' />
              </div>
            </>
          )}
          {userStore.user.isAdmin && (
            <div className='header__buttons-item'>
              <Button label='header.btn.logout' onClick={userStore.logout} BtnPic={UserIcon} />
            </div>
          )}
        </div>
      </header>
    );
  }
}
