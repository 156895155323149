import {inject, observer} from 'mobx-react';
import React, {Component} from 'react';
import classNames from 'classnames';

import crossPic from '../../../img/cross.svg';
import {I18n} from '../i18n/I18n';

@inject('rootStore')
@observer
export default class SidePanel extends Component {
  togglePanel = () => {
    const {sidePanelStore} = this.props.rootStore;
    sidePanelStore.togglePanel(this.props.panelName);
  };

  render() {
    const {headerPic, headerTitle, panelName, rootStore} = this.props;
    const {sidePanelStore} = rootStore;
    const {activePanels, returnPanelOrder} = sidePanelStore;
    const style = {zIndex: `${returnPanelOrder(panelName) + 200}`}; // to be higher then cards
    const isActive = activePanels.includes(panelName);
    return (
      <div style={style} className={classNames('side-panel', {'side-panel--active': isActive})}>
        <div className='side-panel-header '>
          <div className='panel-header-item'>
            <img src={headerPic} alt='header-pic' />
            <I18n className='item-label item-label--lg' tKey={headerTitle} />
          </div>
          <div className='panel-header-item clickable' onClick={this.togglePanel}>
            <img src={crossPic} alt='close-pic' />
            <I18n className='item-label' tKey='session.btn.close' />
          </div>
        </div>
        {this.props.children}
      </div>
    );
  }
}
