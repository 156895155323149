import {Redirect, Route, Switch, withRouter} from 'react-router';
import {inject, observer} from 'mobx-react';
import React, {Component} from 'react';

import {CreateSession} from './createSession/CreateSession';
import {ModalListener} from '../modals/ModalListener';
import {SessionRoom} from './sessionRoom/SessionRoom';
import {NoMatch} from './noMatch/NoMatch';
import {Header} from './header/Header';
import {Auth} from './auth/Auth';
import Home from './home/Home';
import {Registration} from './auth/Registration';
import {ResetPassword} from './auth/ResetPassword';
import {AppError} from './appError/AppError';
import {Footer} from './footer/Footer';

@withRouter
@inject('rootStore')
@observer
class MainLayout extends Component {
  render() {
    const {isLoaded, isAppError, userStore} = this.props.rootStore;
    const {user} = userStore;
    if (!isLoaded) {
      return null; // show preloader
    }
    if (isAppError) {
      return <AppError />;
    }
    return (
      <main className='main-content-wrapper default-theme'>
        {user.isAdmin && <Header />}
        <ModalListener />
        {user.isAdmin ? (
          <Switch>
            <Route exact path='/' component={Home} />
            <Route path='/constructor' component={CreateSession} />
            <Route path='/auth' component={() => <Redirect to='/' />} />
            <Route path='/registration' component={() => <Redirect to='/' />} />
            <Route exact path='/room/:id' component={SessionRoom} />
            <Route component={NoMatch} />
          </Switch>
        ) : (
          <Switch>
            <Route exact path={['/', '/constructor']} component={() => <Redirect to='/auth' />} />
            <Route path='/registration' component={Registration} />
            <Route path='/auth' component={Auth} />
            <Route path='/reset' component={ResetPassword} />
            <Route exact path='/room/:id' component={SessionRoom} />
            <Route component={NoMatch} />
          </Switch>
        )}
        <Footer />
      </main>
    );
  }
}

export default MainLayout;
