import {action, observable} from 'mobx';
import {deleteSession, getSessions} from '../utils/requester';

export class AllSessionsStore {
  @observable sessions = {
    isFetching: false,
    list: [],
  };

  @observable sessionIsDeleting = false;

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @action deleteSession = (code) => {
    if (!this.sessionIsDeleting) {
      this.sessionIsDeleting = true;
      deleteSession(code).then((r) => {
        if (!r.isError) {
          this.sessions.list = this.sessions.list.filter((session) => session.code !== code); // convert map to array, remove map key
        }
        this.sessionIsDeleting = false;
      });
    }
  };

  @action getSessions = () => {
    this.sessions.isFetching = true;
    getSessions().then((r) => {
      if (!r.isError && (r.data || []).length) {
        this.sessions.list = r.data.map((elem) => elem[1]); // convert map to array, remove map key
      }
      this.sessions.isFetching = false;
    });
  };
}
