import {action, computed, observable} from 'mobx';
import deck from '../img/deck.svg';
import settings from '../img/settings.svg';

export const PANELS = [
  {value: 'DECK', picUrl: deck},
  {value: 'SETTINGS', picUrl: settings},
];

export class SidePanelStore {
  @observable activePanels = [];

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @computed get panelOrder() {
    // eslint-disable-next-line no-restricted-globals
    return this.returnPanelOrder(name);
  }

  returnPanelOrder = (name) => {
    const curIndex = this.activePanels.findIndex((panelName) => panelName === name);
    if (curIndex >= 0) {
      return curIndex;
    }
    return 0;
  };

  @action togglePanel = (name) => {
    const panelIsOpen = this.activePanels.includes(name);
    if (panelIsOpen) {
      this.activePanels = this.activePanels.filter((panelName) => panelName !== name);
    } else {
      this.activePanels.push(name);
    }
  };
}
