import 'webrtc-adapter';
import React, {Component} from 'react';
import {Rnd} from 'react-rnd';
import Video from './components/Video';

export class MainVideo extends Component {
  render() {
    return (
      <Rnd
        style={{
          position: 'fixed',
          right: '100px',
          bottom: '100px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: 'solid 1px #ddd',
          background: '#f0f0f0',
          zIndex: 9999,
        }}
        default={{
          x: 100,
          y: 50,
          width: 260,
          height: 200,
        }}
      >
        <div className='panel sidebar'>
          <Video
            roomId={this.props.roomId}
            closeAction={(a) => console.log(a)}
            signalServer={process.env.REACT_APP_SIGNAL_VIDEO_SERVER}
          />
        </div>
      </Rnd>
    );
  }
}
