import {action, computed, observable} from 'mobx';
import {RDR_BLOCK, STORAGE_KEY, WS_MSGS} from '../config/const';
import {AlertModal} from '../components/modals/alertModal/AlertModal';
import {info} from '../utils/requester';
import {getContainerSize, getRandomInt, shuffle} from '../utils/utils';

export const SIZES = [1, 1.5, 2];

export class SessionWsStore {
  ws = null;

  dblClickTimer = null;

  cmpIsAlive = false;

  @observable wsIsFetching = false;

  @observable uploadedDecksIsFetching = false;

  @observable sessionIsEmpty = false; // isEmpty

  @observable session = null; // settings

  @observable decks = [];

  @observable backInTheEnd = false;

  @observable deckSizes = {}; // for btn of deck sizes

  @observable activeSelectionSize = 1; // current size of decks

  @observable showUpdateMsg = false;

  @observable localScaleIndex = 0;

  constructor(rootStore) {
    this.liveInterval = null;
    this.rootStore = rootStore;
  }

  @action
  changeScale = (increase) => {
    const newValue = increase ? this.localScaleIndex + 1 : this.localScaleIndex - 1;
    if (SIZES[newValue]) {
      this.localScaleIndex = newValue;
    }
  };

  @action
  toggleCmpLiveState = (value) => {
    this.cmpIsAlive = value;
  };

  @action
  closeConnection = () => {
    clearInterval(this.liveInterval); // todo recheck maybe it should be removed
    this.ws.close();
    this.ws = null;
    this.dblClickTimer = null;
    this.wsIsFetching = false;
    this.sessionIsEmpty = false;
    this.session = null;
    this.backInTheEnd = false;
    this.deckSizes = {};
    this.activeSelectionSize = 1;
    this.showUpdateMsg = false;
  };

  @computed
  get media() {
    return (this.session || {media: []}).media.filter((elem) => !!elem);
  }

  @computed
  get deckOptionsList() {
    return this.decks.map((deck) => ({value: deck.folder, label: deck.name}));
  }

  @computed
  get localScale() {
    return SIZES[this.localScaleIndex];
  }

  @action
  connectToWs = (sessionId) => {
    const token = localStorage.getItem(STORAGE_KEY);
    const socketUrl = `${process.env.REACT_APP_WS_URL}?token=${token || ''}&session=${sessionId}`;
    this.ws = new WebSocket(socketUrl);
    this.ws.addEventListener('open', () => {
      this.liveInterval = setInterval(() => {
        // set timer to keep alive connection
        this.sendMsg(WS_MSGS.IS_ALIVE);
      }, 30000);
    });

    this.ws.addEventListener('close', () => {
      clearInterval(this.liveInterval);
      // if (!this.state.cmpIsDestroyed && !this.state.isEmpty) {
      // TODO !this.rootStore.userStore.user.isAdmin looks strange recheck!!!!
      if (this.cmpIsAlive && !this.session?.trialMode && !this.rootStore.userStore.user.isAdmin) {
        setTimeout(() => this.connectToWs(sessionId), 1000);
        // this.props.modalStore.open(AlertModal, null, null, {txt: "Ваше соединение закрыто"});
      }
    });

    this.ws.addEventListener('message', this.processMsg);

    this.ws.addEventListener('error', () => {
      this.rootStore.modalStore.open(AlertModal, null, null, {txt: 'Ошибка соединения'});
    });

    if (this.rootStore.userStore.user.isAdmin) {
      this.getUploadedDecks();
    }
  };

  @action
  getUploadedDecks = () => {
    this.uploadedDecksIsFetching = true;
    info().then((r) => {
      this.uploadedDecksIsFetching = false;
      if (!r.isError && (r.data.decks || []).length) {
        this.decks = r.data.decks;
      }
    });
  };

  @action
  processMsg = (ev) => {
    const msg = JSON.parse(ev.data);
    if (msg.type === WS_MSGS.EMPTY_SESSION) {
      this.sessionIsEmpty = true;
    } else if (msg.type === WS_MSGS.WRONG_TOKEN) {
      this.rootStore.userStore.logout();
      this.props.history.push('/auth'); // TODO redirect
    } else if (
      msg.type === WS_MSGS.CONNECTED ||
      msg.type === WS_MSGS.UPDATE_SETTINGS ||
      msg.type === WS_MSGS.UPDATE_MAIN_SETTINGS
    ) {
      this.session = msg.data.settings;
      this.showUpdateMsg = msg.type === WS_MSGS.UPDATE_MAIN_SETTINGS;
      if (msg.type === WS_MSGS.UPDATE_MAIN_SETTINGS) {
        setTimeout(() => {
          this.showUpdateMsg = false;
        }, 1000);
      }
    }
  };

  findSelectedCard = (cardId) => {
    return this.session.activeSelections.find((card) => card.id === cardId);
  };

  @action
  makeLocalChanges = (type, data) => {
    if (type === WS_MSGS.ANIMATED_SELECTION) {
      const {activeSelections} = this.session;
      const newSelectedCards = [...activeSelections, data];
      if (this.canSelectCard()) {
        this.dblClickTimer = setTimeout(() => {
          this.session = {...this.session, activeSelections: newSelectedCards};
          this.dblClickTimer = null;
        }, 1000);
      }
    } else if (type === WS_MSGS.TOGGLE_VIEW) {
      const decks = [...this.session.media];
      const deck = decks.find((elem) => elem?.folder === data.deckName);
      if (deck) {
        deck.isHorizontal = !deck.isHorizontal;
        this.session = {...this.session, media: decks};
      }
    } else if (type === WS_MSGS.TOGGLE_ACTIVE_CARD) {
      console.log(1);
      const card = (this.session.activeSelections || []).find(
        (elem) => elem.deckId === data.deckId && elem.id === data.cardId
      );
      if (card) {
        card.isFlipped = !card.isFlipped;
        this.session.activeSelections = [...this.session.activeSelections];
      }
    } else if (type === WS_MSGS.MIX_ACTIVE) {
      const activeSelections = shuffle([...this.session.activeSelections]);
      this.session = {...this.session, activeSelections};
    } else if (type === WS_MSGS.RETURN_INTO_DECK) {
      const returnedCard = data || {};
      const media = [...this.session.media];
      const mediaObj = media.findIndex((elem) => elem?.folder === returnedCard.folder);
      if (mediaObj >= 0) {
        const fileIndex = media[mediaObj].files.findIndex((file) => file.id === returnedCard.id);
        if (fileIndex >= 0) {
          const {files} = media[mediaObj];
          const randomEIndex = getRandomInt(files.length);
          const temp = {...files[randomEIndex]};
          files[randomEIndex] = {...files[fileIndex]};
          files[fileIndex] = temp;
        }
      }

      const activeSelections = [...this.session.activeSelections].filter((card) => card.id !== returnedCard.id);
      this.session = {...this.session, activeSelections};
    } else if (type === WS_MSGS.REMOVE_ACTIVE_SELECTIONS) {
      const {activeSelections} = this.session;
      activeSelections.forEach((card) => {
        const media = [...this.session.media];
        const mediaObj = media.findIndex((elem) => elem?.folder === card.folder);
        if (mediaObj >= 0) {
          const fileIndex = media[mediaObj].files.findIndex((file) => file.id === card.id);
          if (fileIndex >= 0) {
            const {files} = media[mediaObj];
            const randomEIndex = getRandomInt(files.length);
            const temp = {...files[randomEIndex]};
            files[randomEIndex] = {...files[fileIndex]};
            files[fileIndex] = temp;
          }
        }
      });
      this.session = {...this.session, activeSelections: []};
    } else if (type === WS_MSGS.CARD_ROTATED) {
      const card = this.findSelectedCard(data.cardId);
      if (card) {
        card.rotate = data.rotate;
      }
    } else if (type === WS_MSGS.CARD_DRAGGED) {
      const card = this.findSelectedCard(data.cardId);
      if (card) {
        card.translate = data.translate;
      }
    } else if (type === WS_MSGS.CARD_RESIZED) {
      const card = this.findSelectedCard(data.cardId);
      if (card) {
        card.translate = data.translate;
        card.scale = data.scale;
      }
    } else if (type === WS_MSGS.CHANGE_INDEX) {
      const curIndex = this.session.activeSelections.findIndex((card) => card.id === data.cardId);
      if (curIndex > -1) {
        const removed = this.session.activeSelections.splice(curIndex, 1);
        this.session.activeSelections.push(removed[0]);
      }
    }
  };

  sendMsg = (type, data = {}) => {
    if (!this.session.trialMode || this.rootStore.userStore.user.isAdmin) {
      this.ws.send(JSON.stringify({type, data}));
    } else {
      this.makeLocalChanges(type, data);
    }
  };

  canSelectCard = () => {
    const {activeSelections, cardsCount} = this.session;
    const activeSelectionsLength = activeSelections.filter((card) => !card.empty).length;
    return (
      this.session.pickExtraCard ||
      ((cardsCount === -1 || Number(cardsCount) > activeSelectionsLength) && !this.dblClickTimer)
    );
  };

  selectCard = (folder, file, shirt) => {
    const viewContainer = getContainerSize(RDR_BLOCK);
    const data = {folder, ...file, viewContainer};
    if (this.session.trialMode || !this.rootStore.userStore.user.isAdmin) {
      data.cardShirt = shirt;
    }
    this.canSelectCard() && this.sendMsg(WS_MSGS.ANIMATED_SELECTION, data);
  };
}
