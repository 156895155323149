import {inject, observer} from 'mobx-react';
import React, {Component} from 'react';

import {MainVideo} from '../video/MainVideo';
import {I18n} from '../../helpers/i18n/I18n';
import {SidePanels} from './SidePanels';
import RdrCard from './RdrCard';
import {PANELS} from '../../../stores/SidePanelStore';

import {RDR_BLOCK} from '../../../config/const';

@inject('rootStore')
@observer
export class SessionRoom extends Component {
  componentDidMount() {
    this.props.rootStore.sessionWsStore.toggleCmpLiveState(true);
    this.props.rootStore.sessionWsStore.connectToWs(this.props.match.params.id);
  }

  componentWillUnmount() {
    this.props.rootStore.sessionWsStore.toggleCmpLiveState(false);
    this.props.rootStore.sessionWsStore.closeConnection();
  }

  togglePanel = (name) => {
    const {sidePanelStore} = this.props.rootStore;
    sidePanelStore.togglePanel(name);
  };

  render() {
    const {rootStore} = this.props;
    const {sessionWsStore, themeStore} = rootStore;
    const {boardBg} = themeStore;
    const {session, sessionIsEmpty} = sessionWsStore;
    if (!session) {
      return <div className='session-page'>{sessionIsEmpty && <I18n tKey='errors.empty_session' />}</div>;
    }
    return (
      <div className='session-field'>
        {session.withVideo && <MainVideo roomId={session.code} />}
        <div
          id={RDR_BLOCK}
          style={boardBg ? {backgroundImage: `url(${boardBg})`} : {backgroundColor: '#fff'}}
          className='field-session'
        >
          <div className='field-session__panel-btns'>
            <button
              type='button'
              onClick={this.togglePanel.bind(this, PANELS[0].value)}
              className='field-session__panel-btns-item'
            >
              <img alt='controll btn' src={PANELS[0].picUrl} />
            </button>
            <button
              type='button'
              onClick={this.togglePanel.bind(this, PANELS[1].value)}
              className='field-session__panel-btns-item'
            >
              <img alt='controll btn' src={PANELS[1].picUrl} />
            </button>
          </div>
          {session.activeSelections.map(
            ({isFlipped, cardShirt, scale, originalWidth, originalHeight, id, url, translate, rotate, deckId}, i) => (
              <RdrCard
                id={id}
                key={id}
                index={i}
                maxIndex={session.activeSelections.length}
                media={session.media}
                isFlipped={isFlipped}
                deckId={deckId}
                cardShirt={cardShirt}
                translate={translate}
                rotate={rotate}
                originalWidth={originalWidth}
                originalHeight={originalHeight}
                scale={scale}
                cardFaceImg={url}
              />
            )
          )}
          <SidePanels />
        </div>
      </div>
    );
  }
}
