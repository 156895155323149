import React, {Component} from 'react';

import {inject, observer} from 'mobx-react';
import {I18n} from '../../helpers/i18n/I18n';

// import {Input} from '../../helpers/input/Input';
// import {Dropdown} from '../../helpers/dropdown/Dropdown';
import {Table} from './Table';
import {DATE_FORMATS} from '../../../config/const';
import {TableSessionTools} from './TableSessionTools';

// import {ReactComponent as EditIcon} from '../../../img/edit.svg';

// temporary
export const options = [
  {value: 'newerst', label: 'Newerst'},
  {value: 'older', label: 'Older'},
  {value: 'latest', label: 'Latest'},
];

const tableData = {
  columns: [
    {
      name: 'home.table.col.session_name',
      key: 'sessionName',
    },
    {
      name: 'home.table.col.status',
      render: (data) => (
        <div>
          <I18n tKey='common.online' /> ({(data.activeConnections || []).length})
        </div>
      ),
    },
    {name: 'home.table.col.date', key: 'createdAt', parserFormat: DATE_FORMATS.SESSION_DATE},
    {name: 'home.table.col.time', key: 'createdAt', parserFormat: DATE_FORMATS.TIME},
    // {name: 'home.table.col.duration', key: 'duration', parserFormat: DATE_FORMATS.DURATION},
    // {name: 'home.table.col.clients', key: 'clients'},
    // {
    //   name: 'home.table.col.description',
    //   render: () => (
    //     <div className='description-row'>
    //       <p className='description-row__txt'>
    //         Maecenas a nunc elementum hdgf gdgjd dsjdsjdhjs
    //         {/* <I18n tKey='No Description' /> */}
    //       </p>
    //       {/* <EditIcon /> */}
    //     </div>
    //   ),
    // },
    {
      name: 'home.table.col.tools',
      render: (data) => <TableSessionTools data={data} />,
    },
  ],
};

@inject('rootStore')
@observer
class Home extends Component {
  componentDidMount() {
    this.props.rootStore.allSessionsStore.getSessions();
  }

  inputChange = (prop, val) => {
    this.setState({[prop]: val});
  };

  render() {
    const {sessions} = this.props.rootStore.allSessionsStore;
    return (
      <div className='home-page'>
        <div className='home-page__container'>
          <div className='home-page__header'>
            <h1 className='home-page__title'>
              <I18n tKey='Dashboard' />
            </h1>
            {/* <div className='home-page__fields'> */}
            {/*  <div className='home-page__input-item'> */}
            {/*    <Input */}
            {/*      value={searchValue} */}
            {/*      name='searchValue' */}
            {/*      label='Search' */}
            {/*      placeholder='Type something' */}
            {/*      lgSize */}
            {/*      onChange={this.inputChange} */}
            {/*    /> */}
            {/*  </div> */}
            {/*  <div className='home-page__sort-item'> */}
            {/*    <Dropdown defaultValue={options[0]} label='Sort by' lgSize options={options} /> */}
            {/*  </div> */}
            {/* </div> */}
          </div>
          {sessions.isFetching ? <I18n tKey='common.loading' /> : <Table data={{...tableData, rows: sessions.list}} />}
        </div>
      </div>
    );
  }
}

export default Home;
