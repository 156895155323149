import {withRouter} from 'react-router';
import React, {Component} from 'react';

import {I18n} from '../../helpers/i18n/I18n';

@withRouter
export class Footer extends Component {
  render() {
    const isShown = /\/constructor|\/registration|\/auth/.test(this.props.history.location.pathname);
    if (!isShown) {
      return null;
    }
    return (
      <footer className='footer-main'>
        <div className='footer-links'>
          <a className='footer-links__item' href='/'>
            <I18n tKey='footer.about_us' />
          </a>
          <a className='footer-links__item' href='/'>
            <I18n tKey='footer.contacts' />
          </a>
          <a className='footer-links__item' href='/'>
            <I18n tKey='footer.terms' />
          </a>
          <a className='footer-links__item' href='/'>
            <I18n tKey='footer.help' />
          </a>
        </div>
      </footer>
    );
  }
}
