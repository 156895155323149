import React, {Component} from 'react';
import classNames from 'classnames';
import {I18n} from '../i18n/I18n';

export class CopyField extends Component {
  state = {
    timer: null,
  };

  copyField = React.createRef();

  setTimer() {
    return setTimeout(() => {
      this.setState({timer: null, show: false});
    }, 1000);
  }

  copyTxt = () => {
    if (!this.state.timer) {
      this.copyField.current.value = '';
      this.copyField.current.value = this.props.copyTxtValue || this.props.txt;
      this.copyField.current.select();
      this.copyField.current.setSelectionRange(0, 99999);
      document.execCommand('copy');
      this.setState({timer: this.setTimer(), show: true});
    }
  };

  render() {
    const {txt, cbTxt = 'Скопировано'} = this.props;
    return (
      <div className='copy-field-container'>
        <input className='copy-field-container__value' ref={this.copyField} type='text' defaultValue={txt} />
        <div className='copy-field-container__trigger' onClick={this.copyTxt}>
          <I18n tKey={txt} />
        </div>
        <div
          className={classNames('copy-field-container__done', {'copy-field-container__done--shown': this.state.show})}
        >
          <I18n tKey={cbTxt} />
        </div>
      </div>
    );
  }
}
