import BigNumber from 'bignumber.js';

export function shuffle(arr) {
  let j;
  let temp;
  for (let i = arr.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    temp = arr[j];
    arr[j] = arr[i];
    arr[i] = temp;
  }
  return arr;
}

export function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

export function getContainerSize(id) {
  const size = {w: 0, h: 0};
  const el = document.querySelector(`#${id}`);
  if (!el) {
    return size;
  }
  size.w = el.offsetWidth;
  size.h = el.offsetHeight;
  return size;
}

export const convertOffsetToPercents = (parentId, offset, isVertical, parentSize) => {
  let maxSize;
  if (parentId) {
    const {w, h} = getContainerSize(parentId);
    maxSize = isVertical ? h : w;
  } else {
    maxSize = parentSize;
  }
  return new BigNumber(100).multipliedBy(offset).div(maxSize).toFixed(2);
};

export const convertPercentsToOffset = (parentId, percent, isVertical, parentSize) => {
  let maxSize;
  if (parentId) {
    const {w, h} = getContainerSize(parentId);
    maxSize = isVertical ? h : w;
  } else {
    maxSize = parentSize;
  }
  return new BigNumber(percent).div(100).multipliedBy(maxSize).toFixed(0);
};
