import {inject, observer} from 'mobx-react';
import React, {Component} from 'react';

// import {RadioButton} from '../../helpers/radioButton/RadioButton';
// import {CARDS_COUNT_OPTIONS} from '../createSession/CreateSession';
import {BgSelector} from '../../helpers/bgSelector/BgSelector';
import {Dropdown} from '../../helpers/dropdown/Dropdown';
import {CheckBox} from '../../helpers/checkBox/CheckBox';
import {Button} from '../../helpers/button/Button';
import {WS_MSGS} from '../../../config/const';
import {I18n} from '../../helpers/i18n/I18n';
// import {BgUploader} from './BgUploader';

// import bgPic from './bg.jpg';

// import {ReactComponent as BtnPic} from '../../../img/upload.svg';

@inject('rootStore')
@observer
export class SessionSettings extends Component {
  constructor(props) {
    super(props);
    const {session, decks} = this.props.rootStore.sessionWsStore;
    this.state = {
      defaultDeck: {
        value: session.deckFolder,
        label: decks.find((deck) => deck.folder === session.deckFolder)?.name || session.deckFolder,
      },
      backInTheEnd: session.backInTheEnd,
      additionalDecks: session.additionalDecks,
      cardsCount: session.cardsCount,
      additionalDeck: null,
    };
  }

  inputChange = (prop, val) => {
    const {additionalDecks} = this.state;
    const newState = {[prop]: val};
    if (prop === 'defaultDeck' && additionalDecks.length) {
      newState.additionalDecks = [];
    }
    this.setState(newState);
  };

  filterAdditionalDecks = (deck) => {
    const {defaultDeck, additionalDecks} = this.state;
    return deck.value !== defaultDeck.value && !(additionalDecks || []).some((elem) => elem === deck.value);
  };

  toggleAdditionalDeck = (deck) => {
    const {sendMsg} = this.props.rootStore.sessionWsStore;
    sendMsg(WS_MSGS.ADD_DECK, {folder: deck});
  };

  toggleTrial = () => {
    const {sendMsg} = this.props.rootStore.sessionWsStore;
    sendMsg(WS_MSGS.TOGGLE_TRIAL, null);
  };

  toggleBox = (prop, value) => {
    const newState = {[prop]: value};
    this.setState(newState);
  };

  addDeck = () => {
    const {additionalDecks, additionalDeck} = this.state;
    const newAdditionalDecks = [...additionalDecks];
    newAdditionalDecks.push(additionalDeck.value);
    this.setState({additionalDeck: null, additionalDecks: newAdditionalDecks});
  };

  removeDeck = (index) => {
    const {additionalDecks} = this.state;
    const newAdditionalDecks = [...additionalDecks];
    newAdditionalDecks.splice(index, 1);
    this.setState({additionalDecks: newAdditionalDecks});
  };

  saveSettings = () => {
    const {sendMsg} = this.props.rootStore.sessionWsStore;
    const {defaultDeck, backInTheEnd, additionalDecks, cardsCount} = this.state;
    sendMsg(WS_MSGS.UPDATE_MAIN_SETTINGS, {
      defaultDeck: defaultDeck.value,
      backInTheEnd,
      additionalDecks: additionalDecks.map((deck) => deck),
      cardsCount,
    });
  };

  render() {
    const {media, session, deckOptionsList, showUpdateMsg} = this.props.rootStore.sessionWsStore;
    const {user} = this.props.rootStore.userStore;
    const {
      defaultDeck,
      backInTheEnd,
      additionalDecks,
      // cardsCount,
      additionalDeck,
    } = this.state;
    if (!session) {
      return null;
    }
    if (!user.isAdmin) {
      return (
        <div className='session-settings'>
          <div className='session-settings__item'>
            <p className='session-settings__item-label session-settings__item-label--offset'>
              <I18n tKey='session.options_panel.choose_bg' />
            </p>
            <div className='session-settings__bg-selector'>
              <BgSelector />
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className='session-settings'>
        {/* <div className='session-settings__item'> */}
        {/*  <p className='session-settings__item-label session-settings__item-label--offset'> */}
        {/*    <I18n tKey='session.options_panel.uploaded_bg' /> */}
        {/*  </p> */}
        {/*  <div className='uploader-block'> */}
        {/*    <div className='uploader-block__item'> */}
        {/*      <BgUploader bgImg={bgPic} bgTitle='My background #1 test' /> */}
        {/*    </div> */}
        {/*    <div className='uploader-block__item'> */}
        {/*      <BgUploader bgImg={bgPic} bgTitle='My background #2 test' /> */}
        {/*    </div> */}
        {/*  </div> */}
        {/* </div> */}
        {/* <div className='session-settings__item'> */}
        {/*  <p className='session-settings__item-label session-settings__item-label--offset'> */}
        {/*    <I18n tKey='session.options_panel.upload_your_bg' /> */}
        {/*  </p> */}
        {/*  <div className='session-settings__item-btns'> */}
        {/*    <Button label='session.options_panel.btn.upload_bg' altTheme BtnPic={BtnPic} lgSize boldFont /> */}
        {/*    <p className='session-settings__btn-subtitle'> */}
        {/*      <I18n tKey='session.options_panel.settings_bg' /> */}
        {/*    </p> */}
        {/*  </div> */}
        {/* </div> */}
        <div className='session-settings__item'>
          <p className='session-settings__item-label session-settings__item-label--offset'>
            <I18n tKey='session.options_panel.choose_bg' />
          </p>
          <div className='session-settings__bg-selector'>
            <BgSelector />
          </div>
        </div>

        <div className='session-settings__item'>
          <CheckBox onChange={this.toggleTrial} label='session.controls.trial_mode' isChecked={session.trialMode} />
        </div>

        {(session.additionalDecks || []).length > 0 && (
          <div className='session-settings__item'>
            <p className='session-settings__item-label session-settings__item-label--offset'>
              <I18n tKey='session.controls.additional_decks' />
            </p>
            <div className='checkbox-list'>
              {session.additionalDecks.map((deck, i) => (
                <div className='checkbox-list__item' key={i}>
                  <CheckBox
                    onChange={this.toggleAdditionalDeck.bind(this, deck)}
                    label={deck}
                    isChecked={media.some((elem) => elem.folder === deck)}
                  />
                </div>
              ))}
            </div>
          </div>
        )}

        <div className='settings-edit-form'>
          {/* <div className='session-settings__item'> */}
          {/*  <p className='session-settings__item-label session-settings__item-label--offset'> */}
          {/*    <I18n tKey='session.options_panel.cards_allowed' /> */}
          {/*  </p> */}
          {/*  <div className='session-settings__item-btns'> */}
          {/*    {CARDS_COUNT_OPTIONS.map((option) => ( */}
          {/*      <div key={option.value} className='session-settings__item-btn'> */}
          {/*        <RadioButton */}
          {/*          onChange={this.inputChange} */}
          {/*          name='cardsCount' */}
          {/*          label={option.label} */}
          {/*          value={option.value} */}
          {/*          isChecked={option.value === cardsCount} */}
          {/*        /> */}
          {/*      </div> */}
          {/*    ))} */}
          {/*  </div> */}
          {/* </div> */}

          <div className='session-settings__item'>
            <p className='session-settings__item-label session-settings__item-label--offset'>
              <I18n tKey='session.options_panel.options_label' />
            </p>
            <CheckBox
              onChange={this.toggleBox}
              name='backInTheEnd'
              label='session.options_panel.check_box_put_in_the_end'
              value={!backInTheEnd}
              isChecked={backInTheEnd}
            />
          </div>

          <div className='session-settings__item'>
            <Dropdown
              placeholder='Выберите колоду'
              label='constructor.label.main_deck'
              lgSize
              options={deckOptionsList.filter((deck) => deck.value !== defaultDeck.value)}
              onChange={this.inputChange.bind(this, 'defaultDeck')}
              value={defaultDeck}
            />
          </div>

          <div className='session-settings__item'>
            <div className='settings-edit-form__row'>
              <Dropdown
                placeholder='Выберите колоду'
                label='constructor.label.second_deck'
                lgSize
                onChange={this.inputChange.bind(this, 'additionalDeck')}
                options={deckOptionsList.filter(this.filterAdditionalDecks)}
                value={additionalDeck}
              />
              <div className='create-form__btn-wrapper'>
                <Button
                  disabled={!additionalDeck}
                  onClick={this.addDeck}
                  label='constructor.btn.add_deck'
                  mdSize
                  fillContainer
                />
              </div>
            </div>
          </div>

          {additionalDecks.map((deck, i) => (
            <div key={i} className='session-settings__item'>
              <div className='settings-edit-form__row'>
                <div className='select-wrapper'>
                  <div className='select-wrapper__label select-wrapper__label--lg-pd'>
                    <I18n tKey='constructor.label.another_deck' />
                  </div>
                  <div className='create-form__row-item-selected'>
                    <span>{deck}</span>
                  </div>
                </div>
                <div className='create-form__btn-wrapper'>
                  <Button onClick={this.removeDeck.bind(this, i)} label='common.delete' mdSize fillContainer />
                </div>
              </div>
            </div>
          ))}
          <div className='settings-edit-form__save'>
            {showUpdateMsg && <I18n className='settings-edit-form__save-msg' tKey='Изменения сохранены' />}
            <Button onClick={this.saveSettings} label='session.controls.save_changes' altTheme lgSize boldFont />
          </div>
        </div>
      </div>
    );
  }
}
