import React, {Component} from 'react';
import ReactModal from 'react-modal';
import {withRouter} from 'react-router-dom';
import {I18n} from '../../helpers/i18n/I18n';
import {CopyField} from '../../helpers/copyField/CopyField';
import {Button} from '../../helpers/button/Button';

ReactModal.setAppElement(document.body);

@withRouter
export class SessionCreateResult extends Component {
  copyRef = React.createRef();

  copyLink = () => {
    if (this.copyRef.current) {
      this.copyRef.current.copyTxt();
    }
  };

  goToSession = () => {
    const {history, params, closeModal} = this.props;
    closeModal();
    history.push(params.url);
  };

  render() {
    const {isOpen, closeModal, params} = this.props;
    return (
      <ReactModal
        overlayClassName='modal-dark-overlay'
        className='session-create-modal'
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        isOpen={isOpen}
      >
        {params.header && (
          <h1>
            <I18n tKey={params.header} />
          </h1>
        )}
        {params.url && (
          <div className='session-link'>
            <CopyField
              ref={this.copyRef}
              cbTxt='common.done'
              txt={`${window.location.protocol}//${window.location.host}${params.url}`}
            />
            <div className='session-link__btn'>
              <Button smSize onClick={this.copyLink} label='common.copy' />
            </div>
          </div>
        )}
        {params.error && (
          <div>
            <I18n className='warning-txt' tKey={params.error} />
          </div>
        )}
        {params.url && (
          <div className='session-create-modal__footer'>
            <Button altTheme smSize onClick={this.goToSession} label='Перейти' />
          </div>
        )}
      </ReactModal>
    );
  }
}
