import * as axios from 'axios';
import {STORAGE_KEY} from '../config/const';

export const BASE_URL = process.env.REACT_APP_URL;

export const getXToken = () => localStorage.getItem(STORAGE_KEY);

const Fetcher = axios.create({
  baseURL: BASE_URL,
});

export const getHeader = (header = {}) => {
  return {
    Authorization: JSON.stringify(getXToken()),
    ...header,
  };
};

const GET = (url, data, headers) => Fetcher.request({url, data, method: 'GET', headers: getHeader(headers)});
const POST = (url, data, headers) => Fetcher.request({url, data, method: 'POST', headers: getHeader(headers)});
// const PUT = (url, data, headers) => Fetcher.request({url, data, method: 'PUT', headers: getHeader(headers)});
const DELETE = (url, data, headers) => Fetcher.request({url, data, method: 'DELETE', headers: getHeader(headers)});

async function makeCall(method, url, data, headers = {}) {
  let result = null;
  try {
    const resp = await method(url, data, headers);
    result = {
      data: resp.data,
      isError: false,
      url,
      response: resp,
    };
  } catch (e) {
    console.error(e);
    if (e.response?.status === 403) {
      // window.stores.app.stopSession();
    }
    result = {
      data: e.response?.data || {},
      errorDescription: {status: e.response?.status, code: e.response?.statusText},
      isError: true,
      url,
      response: e.response,
    };
  }

  return result;
}

export const makeLogin = (password, login) => makeCall(POST, `/auth`, {login, password});
export const register = (email, name, password) => makeCall(POST, `/register`, {email, name, password});
export const resetPassword = (email) => makeCall(POST, `/resetPassword`, {email});
export const deleteSession = (id) => makeCall(DELETE, `/session/delete`, {id});
export const createSession = (data) => makeCall(POST, `/session/create`, data);
export const getSessions = () => makeCall(GET, `/session/all`);
export const checkToken = () => makeCall(GET, '/token');
export const info = () => makeCall(GET, '/info');
