import {inject} from 'mobx-react';
import React, {Component} from 'react';

import {Link} from 'react-router-dom';
import {Input} from '../../helpers/input/Input';
import {Button} from '../../helpers/button/Button';
import {I18n} from '../../helpers/i18n/I18n';

@inject('rootStore')
export class Auth extends Component {
  state = {
    password: '',
    email: 'Admin',
    loginError: '',
    isFetching: false,
  };

  inputChange = (prop, val) => {
    this.setState({[prop]: val});
  };

  login = () => {
    if (this.state.password && this.state.email) {
      const {userStore} = this.props.rootStore;
      this.setState({isFetching: true});
      userStore.login(this.state.password, this.state.email).then((r) => {
        const newState = {isFetching: false, loginError: false};
        if (r.isError) {
          newState.loginError = r.data.message;
        }
        this.setState(newState);
      });
    }
  };

  render() {
    const {password, email, loginError, isFetching} = this.state;

    return (
      <div className='auth-page'>
        <div className='auth-page__content'>
          <div className='auth-form'>
            <div className='auth-form__input'>
              <Input label='auth.login.email' onChange={this.inputChange} name='email' value={email} />
            </div>
            <div className='auth-form__input'>
              <Input
                isPassword
                showEye
                label='auth.login.password'
                onChange={this.inputChange}
                name='password'
                value={password}
              />
            </div>
          </div>
          <div className='auth-page__actions auth-actions'>
            <div className='auth-actions__item'>
              <Button altTheme onClick={this.login} disabled={isFetching} label='auth.btn.login' />
            </div>
            <div className='auth-actions__item'>
              <Link to='/registration'>
                <I18n tKey='auth.login.toRegistration' />
              </Link>
            </div>
          </div>
          <div className='auth-actions__item'>
            <div className='auth-actions__item'>&nbsp;</div>
            <Link to='/reset'>
              <I18n tKey='auth.login.toResetPassword' />
            </Link>
          </div>
          {loginError && (
            <div className='auth-actions__msg'>
              <I18n className='warning-txt' tKey={loginError} />
            </div>
          )}
        </div>
      </div>
    );
  }
}
