import React, {Component} from 'react';
import ReactModal from 'react-modal';

ReactModal.setAppElement(document.body);

export class AlertModal extends Component {
  render() {
    const {isOpen, closeModal, params} = this.props;
    return (
      <ReactModal
        overlayClassName='modal-dark-overlay'
        className='alert-modal'
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
        isOpen={isOpen}
      >
        <div>{params.txt}</div>
      </ReactModal>
    );
  }
}
