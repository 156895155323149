import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {BgSelectorItem} from './BgSelectorItem';

import bg1 from '../../../img/bord_bg/1.jpg';
import bg2 from '../../../img/bord_bg/2.jpg';
import bg3 from '../../../img/bord_bg/3.jpg';
import bg4 from '../../../img/bord_bg/4.jpg';

const BG_PIC = [bg1, bg2, bg3, bg4];

@inject('rootStore')
@observer
export class BgSelector extends Component {
  setBg = (url) => {
    this.props.rootStore.themeStore.setBg(url);
  };

  render() {
    const boardBg = this.props.rootStore.themeStore.boardBg || BG_PIC[0];
    return BG_PIC.map((el, i) => (
      <div className='selector-wrapper--offset' key={i}>
        <BgSelectorItem setBg={this.setBg} bgImg={el} isChecked={boardBg === el} />
      </div>
    ));
  }
}
