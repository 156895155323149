import React from 'react';

import {IconButton} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';

import FlipCameraIcon from '@material-ui/icons/FlipCameraAndroid';
import VideocamOnIcon from '@material-ui/icons/Visibility';
import VideocamOffIcon from '@material-ui/icons/VisibilityOff';
import CancelIcon from '@material-ui/icons/Cancel';

import io from 'socket.io-client';
import {Mic, MicOff} from '@material-ui/icons';
import {getStream} from '../utils/MediaUtils';
import {createSimplePeer} from '../utils/PeerUtils';

import Notifications from './Notifications';

const styles = (theme) => ({
  videoWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  hidden: {
    display: 'none',
  },
  floatingVideo: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    minWidth: '100px',
    width: '25%',
    height: '30%',
    zIndex: 100,
    backgroundColor: '#FFF',
    padding: '2px',
    borderRadius: '8px',
    boxShadow:
      'rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px',
    '& video': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: '6px',
    },
  },
  fullVideo: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#010101',
    borderRadius: 0,
    '& video': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  halfVideo: {
    width: '50%',
    height: '50%',
    flex: '0 0 50%',
    backgroundColor: '#010101',
    borderRadius: 0,
    '& video': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  roundedVideo: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  bottomRightButtons: {
    position: 'absolute',
    right: theme.spacing(2),
    bottom: theme.spacing(2),
  },
  hoverButton: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    color: '#fff',
    boxShadow: '0 1px 3px rgba(0,0,0,0.5), 0 6px 12px rgba(0,0,0,0.5)',
    border: '2px solid rgba(255, 255, 255, 1)',
    margin: theme.spacing(2),
    '&:hover': {
      backgroundColor: `${theme.palette.info.main}F0`,
    },
  },
});

class Video extends React.PureComponent {
  state = {
    socket: {},
    localStream: {},
    remoteStreams: {},
    peers: {},
    connected: false,
    facingMode: 'user',
    localDisabled: false,
    peerConfig: {},
    muted: false,
  };

  // eslint-disable-next-line react/sort-comp
  getPeer = (id) => this.createPeer(id, false);

  createPeer = (id, initiator) => {
    const {peers} = this.state;

    if (peers[id]) {
      // console.log("createPeer", "ALREADY THERE!?", id, peers[id]);
      return peers[id];
    }

    // eslint-disable-next-line no-multi-assign
    const peer = (peers[id] = createSimplePeer(this.state.localStream, initiator, this.state.peerConfig));

    this.setState({peers: {...peers}});

    peer.on('signal', (data) => {
      const signal = {
        from: this.state.socket.id,
        room: id,
        desc: data,
      };
      console.log(data);
      this.state.socket.emit('signal', signal);
    });

    peer.on('stream', (stream) => {
      // if (this.state.remoteStreams[id]) return;
      const remoteStreams = {...this.state.remoteStreams};
      remoteStreams[id] = stream;
      this.setState({remoteStreams});
    });

    peer.once('close', () => {
      this.destroyPeer(id);
      // this.setState({ connecting: true, remoteStream: {}, peer: {} });
    });

    peer.on('error', (err) => {
      console.log(err);
      this.destroyPeer(id);
      // this.setState({ initiator: true, connecting: false, waiting: true })
    });

    return peer;
  };

  destroyPeer = (id) => {
    const {peers} = this.state;

    if (peers[id]) {
      if (peers[id].destroy !== 'undefined') {
        peers[id].destroy();
      }

      delete peers[id];

      const remoteStreams = {...this.state.remoteStreams};
      delete remoteStreams[id];
      this.setState({remoteStreams, peers});
    }
  };

  componentDidMount() {
    const socket = io(this.props.signalServer);
    this.setState({socket});

    const {roomId} = this.props;

    this.getUserMedia(this.state.facingMode).then(() => {
      socket.emit('enter', {roomId});
    });

    socket.on('signal', (signal) => {
      this.getPeer(signal.from).signal(signal.desc);
    });

    socket.on('sockets', ({sockets, peerConfig}) => {
      this.setState({connected: true, peerConfig});

      // eslint-disable-next-line no-restricted-syntax
      for (const id in sockets) {
        if (id !== socket.id) {
          this.createPeer(id, true);
        }
      }
    });

    socket.on('message', (message) => {
      if (message?.type === 'disconnected') {
        this.destroyPeer(message.from);
      } else if (message?.type === 'toggle-stream') {
        const remoteStreams = {...this.state.remoteStreams};

        // eslint-disable-next-line no-underscore-dangle
        remoteStreams[message.from]._enabled = message.enabled;

        this.setState({remoteStreams});
      }
    });

    window.addEventListener('pagehide', this.onPageHide);
  }

  disconnect = () => {
    if (typeof this.state.socket?.close !== 'undefined') {
      this.state.socket.close();
    }

    if (typeof this.state.localStream?.getTracks !== 'undefined') {
      this.state.localStream.getTracks().forEach((track) => track.stop());
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const stream of Object.values(this.state.remoteStreams)) {
      if (typeof stream?.getTracks !== 'undefined') {
        stream.getTracks().forEach((track) => track.stop());
      }
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const peer of Object.values(this.state.peers)) {
      if (typeof peer?.destroy !== 'undefined') {
        peer.destroy();
      }
    }

    this.setState({socket: null, connected: false, localStream: {}, remoteStreams: {}, peers: {}});
  };

  onPageHide = () => {
    this.disconnect();
  };

  componentWillUnmount = () => {
    window.removeEventListener('pagehide', this.onPageHide);

    this.disconnect();
  };

  setLocalVideoStream = (ref) => {
    // eslint-disable-next-line no-cond-assign
    if ((this.localVideo = ref)) {
      if (ref.srcObject !== this.state.localStream && this.state.localStream instanceof MediaStream) {
        ref.srcObject = this.state.localStream;
      }
    }
  };

  setRemoteVideoStream = (ref, stream) => {
    if (ref && stream instanceof MediaStream && ref.srcObject !== stream) {
      ref.srcObject = stream;
    }
  };

  componentDidUpdate() {}

  getUserMedia(facingMode = null) {
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, reject) => {
      getStream(facingMode)
        .then((stream) => {
          if (this.localVideo) {
            this.localVideo.srcObject = stream;
          }

          this.setState({localStream: stream, facingMode});

          // setTimeout(() => { resolve(); }, 3000);
          resolve();
        })
        .catch((error) => {
          console.log('err', error);
        });
    });
  }

  toggleLocalStream = () => {
    // this.state.peer.addStream(this.state.localStream);

    const tracks = this.state.localStream.getTracks();

    // eslint-disable-next-line no-restricted-syntax
    for (const track of tracks) {
      if (track.kind === 'video') {
        track.enabled = !track.enabled;

        this.setState({localDisabled: !track.enabled});

        this.state.socket.emit('message', {
          room: this.props.roomId,
          data: {type: 'toggle-stream', from: this.state.socket.id, enabled: track.enabled},
        });

        break;
      }
    }
  };

  toggleCamera = () => {
    this.state.localStream.getTracks().forEach((track) => track.stop());

    if (this.localVideo) {
      this.localVideo.srcObject = null;
    }

    Object.keys(this.state.peers).forEach((id) => {
      this.state.peers[id].removeStream(this.state.localStream);
    });

    this.getUserMedia(this.state.facingMode === 'user' ? 'environment' : 'user').then(() => {
      Object.keys(this.state.peers).forEach((id) => {
        this.state.peers[id].addStream(this.state.localStream);
      });
    });
  };

  toggleMic = () => {
    console.log(123);
    const stream = this.state.localStream;
    if (stream) {
      for (let i = 0; i < stream.getTracks().length; i++) {
        const track = stream.getAudioTracks()[i];
        if (track) {
          console.log(track);
          track.enabled = !track.enabled;
          this.setState({muted: track.enabled});
        }
        // track.stop();
      }
    }
  };

  render() {
    const {classes} = this.props;

    const remoteUsers = Object.keys(this.state.remoteStreams).length;
    const activeUsers = Object.values(this.state.remoteStreams).reduce(
      // eslint-disable-next-line no-underscore-dangle,no-return-assign
      (count, stream) => (count += stream._enabled !== false ? 1 : 0),
      0
    );

    let localVideoClass;
    let remoteVideoClass;

    if (activeUsers === 0) {
      localVideoClass = classes.fullVideo;
      remoteVideoClass = classes.hidden;
    } else {
      localVideoClass = classes.floatingVideo;
      remoteVideoClass = classes.fullVideo;

      if (activeUsers > 1) {
        // eslint-disable-next-line no-multi-assign
        localVideoClass = remoteVideoClass = classes.halfVideo;
      }
    }

    return (
      <div id='videoWrapper' className={classes.videoWrapper}>
        {/* the local stream ... */}
        {this.state.localDisabled !== true && (
          <div id='localVideoWrapper' className={localVideoClass}>
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <video
              id='localVideo'
              className={classes.roundedVideo}
              ref={this.setLocalVideoStream}
              autoPlay
              playsInline
            />
          </div>
        )}

        {/* the remote streams ... */}
        {Object.entries(this.state.remoteStreams).map(
          ([id, stream]) =>
            // eslint-disable-next-line no-underscore-dangle
            stream._enabled !== false && (
              <div key={`remote-stream-${id}`} className={remoteVideoClass}>
                {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                <video
                  id={`remote-video-${id}`}
                  ref={(ref) => this.setRemoteVideoStream(ref, stream)}
                  autoPlay
                  playsInline
                />
              </div>
            )
        )}

        <Notifications connected={this.state.connected} active={remoteUsers > 0} />

        <div className={classes.bottomRightButtons}>
          {remoteUsers > 0 && (
            <>
              <IconButton className={classes.hoverButton} onClick={(e) => this.toggleCamera(e)}>
                <FlipCameraIcon />
              </IconButton>
              <IconButton className={classes.hoverButton} onClick={(e) => this.toggleLocalStream(e)}>
                {this.state.localDisabled ? <VideocamOnIcon /> : <VideocamOffIcon />}
              </IconButton>
            </>
          )}

          <IconButton className={classes.hoverButton} onClick={this.toggleMic}>
            {this.state.muted ? <MicOff /> : <Mic />}
          </IconButton>

          {this.props.closeAction && (
            <IconButton className={classes.hoverButton} onClick={this.props.closeAction}>
              <CancelIcon />
            </IconButton>
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Video);
