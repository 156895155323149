import React, {Component} from 'react';
import moment from 'moment';
// import classNames from 'classnames';
// import {Link} from 'react-router-dom';
import {I18n} from '../../helpers/i18n/I18n';

// import {ReactComponent as ArrowIcon} from '../../../img/arrow.svg';

export class Table extends Component {
  render() {
    const {data = {}, colClick} = this.props;
    const {columns = [], rows = []} = data;
    return (
      <table className='sessions-table'>
        <thead className='sessions-table__header'>
          <tr>
            {columns.map((column, i) => (
              <th key={i} className='sessions-table__col' onClick={colClick}>
                <I18n tKey={column.name} />
                {/* <ArrowIcon className='sessions-table__col-pic' /> */}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className='sessions-table__body'>
          {rows.map((row, i) => (
            <tr key={i}>
              {columns.map((column, index) => {
                if (column.render) {
                  return (
                    <td className='sessions-table__row' key={index}>
                      {column.render(row)}
                    </td>
                  );
                }
                const rowValue = row[column.key];
                if (column.parserFormat) {
                  return (
                    <td className='sessions-table__row' key={index}>
                      {moment(rowValue).format(column.parserFormat)}
                    </td>
                  );
                }
                return (
                  <td className='sessions-table__row' key={index}>
                    <div>{rowValue}</div>
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}
