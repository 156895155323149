import React, {Component} from 'react';
import classNames from 'classnames';
import {inject, observer} from 'mobx-react';

import {RadioButton} from '../../helpers/radioButton/RadioButton';
import {Dropdown} from '../../helpers/dropdown/Dropdown';
import {CheckBox} from '../../helpers/checkBox/CheckBox';
import {Button} from '../../helpers/button/Button';
import {Input} from '../../helpers/input/Input';
import {I18n} from '../../helpers/i18n/I18n';
import {createSession} from '../../../utils/requester';
import {SessionCreateResult} from '../../modals/sessionCreateResult/SessionCreateResult';

import {ReactComponent as PlusIcon} from '../../../img/plus.svg';
import {ReactComponent as ArrowIcon} from '../../../img/arrow.svg';
// import {ReactComponent as BtnPic} from '../../../img/upload.svg';

export const CARDS_COUNT_OPTIONS = [
  {label: '3', value: 3},
  {label: '4', value: 4},
  {label: '5', value: 5},
  {label: '6', value: 6},
  {label: 'Неограниченное', value: -1},
];

// const CLIENT_SIZE = 5;

@inject('rootStore')
@observer
export class CreateSession extends Component {
  state = {
    sessionIsCreating: false,
    sessionName: '',
    clientName: '',
    defaultDeck: null,
    additionalDeck: null,
    createdClients: [],
    additionalDecks: [],
    isOptionsOpen: false,
    // emailInviteClient: '',
    cardsCount: CARDS_COUNT_OPTIONS[4].value,
    backInTheEnd: false,
    withVideo: false,
    // pickExtraCard: false,
  };

  componentDidMount() {
    this.props.rootStore.sessionWsStore.getUploadedDecks();
  }

  // changeBox = (prop, value) => {
  //   const newState = [...this.state[prop]];
  //   const index = this.state[prop].indexOf(value);
  //   if (index >= 0) {
  //     newState.splice(index, 1);
  //   } else {
  //     newState.push(value);
  //   }
  //   this.setState({[prop]: newState});
  // };

  addClient = () => {
    const {createdClients, clientName} = this.state;
    const newClientsArr = [...createdClients];
    newClientsArr.push(clientName);
    this.setState({clientName: '', createdClients: newClientsArr});
  };

  removeClient = (index) => {
    const {createdClients} = this.state;
    const newClientsArr = [...createdClients];
    newClientsArr.splice(index, 1);
    this.setState({createdClients: newClientsArr});
  };

  removeDeck = (index) => {
    const {additionalDecks} = this.state;
    const newAdditionalDecks = [...additionalDecks];
    newAdditionalDecks.splice(index, 1);
    this.setState({additionalDecks: newAdditionalDecks});
  };

  toggleOption = () => {
    this.setState({isOptionsOpen: !this.state.isOptionsOpen});
  };

  inputChange = (prop, val) => {
    const {additionalDecks} = this.state;
    const newState = {[prop]: val};
    if (prop === 'defaultDeck' && additionalDecks.length) {
      newState.additionalDecks = [];
    }
    this.setState(newState);
  };

  addDeck = () => {
    const {additionalDecks, additionalDeck} = this.state;
    const newAdditionalDecks = [...additionalDecks];
    newAdditionalDecks.push(additionalDeck);
    this.setState({additionalDeck: null, additionalDecks: newAdditionalDecks});
  };

  toggleBox = (prop, value) => {
    const newState = {[prop]: value};
    this.setState(newState);
  };

  createSession = () => {
    const {
      sessionName,
      // clientName,
      // emailInviteClient,
      cardsCount,
      withVideo,
      backInTheEnd,
      defaultDeck,
      additionalDecks,
      // pickExtraCard,
    } = this.state;

    this.setState({sessionIsCreating: true});

    createSession({
      sessionName,
      cardsCount,
      withVideo,
      backInTheEnd,
      defaultDeck: defaultDeck.value,
      additionalDecks: additionalDecks.map((deck) => deck.value),
      pickExtraCard: false,
    }).then((r) => {
      const newState = {sessionIsCreating: false};
      const modalData = {};

      if (r.isError) {
        modalData.header = 'common.error';
        modalData.error = 'errors.session_create';
      } else {
        modalData.url = `/room/${r.data[1].code}`;
        modalData.header = 'common.session_created';
        newState.sessionName = '';
        newState.clientName = '';
        newState.emailInviteClient = '';
        newState.cardsCount = CARDS_COUNT_OPTIONS[0].value;
        newState.backInTheEnd = false;
        newState.defaultDeck = null;
        newState.additionalDecks = [];
        // newState.pickExtraCard = false;
        newState.withVideo = false;
      }

      this.props.rootStore.modalStore.open(SessionCreateResult, null, null, modalData);
      this.setState(newState);
    });
  };

  filterAdditionalDecks = (deck) => {
    const {defaultDeck, additionalDecks} = this.state;
    return deck.value !== defaultDeck.value && !(additionalDecks || []).some((elem) => elem.value === deck.value);
  };

  render() {
    const {
      isOptionsOpen,
      cardsCount,
      sessionName,
      sessionIsCreating,
      // pickExtraCard,
      // clientName,
      // emailInviteClient,
      backInTheEnd,
      defaultDeck,
      additionalDeck,
      additionalDecks,
      // createdClients,
      // withVideo,
    } = this.state;
    // const {deckOptionsList, uploadedDecksIsFetching} = this.props.rootStore.sessionWsStore;
    const {deckOptionsList} = this.props.rootStore.sessionWsStore;
    const picClassParams = {
      'create-form__options-arrow--open': isOptionsOpen,
    };
    return (
      <div className='form-container'>
        <div className='form-container__back-link' onClick={this.props.history.goBack}>
          <ArrowIcon className='form-container__img' />
          <I18n tKey='Back' />
        </div>
        <div className='form-container__create-form create-form'>
          <h1 className='create-form__header'>
            <I18n tKey='constructor.header' />
          </h1>

          <div className='create-form__row-item'>
            <Input
              label='constructor.label.session_name'
              placeholder='constructor.placeholder.enter_name'
              lgSize
              name='sessionName'
              value={sessionName}
              onChange={this.inputChange}
            />
          </div>

          {/* <div className='create-form__row-item'> */}
          {/*  <Input */}
          {/*    label='constructor.label.client_name' */}
          {/*    name='clientName' */}
          {/*    placeholder='constructor.placeholder.enter_name' */}
          {/*    value={clientName} */}
          {/*    onChange={this.inputChange} */}
          {/*    lgSize */}
          {/*  /> */}
          {/*  <div className='create-form__btn-wrapper'> */}
          {/*    <Button */}
          {/*      disabled={createdClients.length >= CLIENT_SIZE || !clientName} */}
          {/*      onClick={this.addClient} */}
          {/*      label='constructor.btn.add_client' */}
          {/*      mdSize */}
          {/*      fillContainer */}
          {/*    /> */}
          {/*  </div> */}
          {/* </div> */}

          {/* {createdClients.map((name, i) => ( */}
          {/*  <div key={i} className='create-form__row-item'> */}
          {/*    <Input */}
          {/*      label='constructor.label.client_name' */}
          {/*      name={name} */}
          {/*      value={name} */}
          {/*      placeholder='constructor.placeholder.enter_name' */}
          {/*      lgSize */}
          {/*      readOnly */}
          {/*    /> */}
          {/*    <div className='create-form__btn-wrapper'> */}
          {/*      <Button onClick={this.removeClient.bind(i)} label='common.delete' mdSize fillContainer /> */}
          {/*    </div> */}
          {/*  </div> */}
          {/* ))} */}

          <div className='create-form__row-item'>
            <Dropdown
              placeholder='Выберите колоду'
              label='constructor.label.main_deck'
              lgSize
              onChange={this.inputChange.bind(this, 'defaultDeck')}
              options={deckOptionsList}
              value={defaultDeck}
            />
            {/* <div className='create-form__btn-wrapper'>
              <Button label='constructor.btn.add_deck' mdSize fillContainer />
            </div> */}
          </div>

          {!!defaultDeck && (
            <div className='create-form__row-item'>
              <Dropdown
                placeholder='Выберите колоду'
                label='constructor.label.second_deck'
                lgSize
                onChange={this.inputChange.bind(this, 'additionalDeck')}
                options={deckOptionsList.filter(this.filterAdditionalDecks)}
                value={additionalDeck}
              />
              <div className='create-form__btn-wrapper'>
                <Button
                  disabled={!additionalDeck}
                  onClick={this.addDeck}
                  label='constructor.btn.add_deck'
                  mdSize
                  fillContainer
                />
              </div>
            </div>
          )}

          {additionalDecks.map((deck, i) => (
            <div key={i} className='create-form__row-item'>
              <div className='select-wrapper'>
                <div className='select-wrapper__label select-wrapper__label--lg-pd'>
                  <I18n tKey='constructor.label.another_deck' />
                </div>
                <div className='create-form__row-item-selected'>
                  <span>{deck.value}</span>
                </div>
              </div>
              <div className='create-form__btn-wrapper'>
                <Button onClick={this.removeDeck.bind(this, i)} label='common.delete' mdSize fillContainer />
              </div>
            </div>
          ))}

          {/* <div className='create-form__row-item'> */}
          {/*  <Input */}
          {/*    label='constructor.label.invite_by_email' */}
          {/*    name='emailInviteClient' */}
          {/*    value={emailInviteClient} */}
          {/*    onChange={this.inputChange} */}
          {/*    placeholder='constructor.placeholder.enter_email' */}
          {/*    lgSize */}
          {/*  /> */}
          {/* </div> */}

          {/* <div className='create-form__row-item'> */}
          {/*  <Input label='constructor.label.share_link' placeholder='https://cards.ru/Qsw/Qsw5492Kjddx' lgSize /> */}
          {/*  <div className='create-form__btn-wrapper'> */}
          {/*    <Button label='constructor.btn.copy_link' mdSize fillContainer /> */}
          {/*  </div> */}
          {/* </div> */}

          <div className='create-form__options-title' onClick={this.toggleOption}>
            <I18n tKey='constructor.advanced_options.title' />
            <ArrowIcon className={classNames('create-form__options-arrow', picClassParams)} />
          </div>

          {isOptionsOpen && (
            <div className='session-other-options'>
              {/* <div className='session-other-options__option'> */}
              {/*  <p className='session-other-options__option-label'> */}
              {/*    <I18n tKey='session.options_panel.upload_your_bg' /> */}
              {/*  </p> */}
              {/*  <div className='session-other-options__bg-upload'> */}
              {/*    <div className='session-other-options__bg-upload-btn'> */}
              {/*      <Button label='session.options_panel.btn.upload_bg' BtnPic={BtnPic} lgSize fillContainer boldFont /> */}
              {/*    </div> */}
              {/*    <p className='session-settings__btn-subtitle'> */}
              {/*      <I18n tKey='session.options_panel.settings_bg' /> */}
              {/*    </p> */}
              {/*  </div> */}
              {/* </div> */}

              <div className='session-other-options__option'>
                <p className='session-other-options__option-label'>
                  <I18n tKey='session.options_panel.cards_allowed' />
                </p>
                <div className='session-other-options__container-radio'>
                  {CARDS_COUNT_OPTIONS.map((el, i) => (
                    <div className='session-other-options__radio' key={i}>
                      <RadioButton
                        name='cardsCount'
                        label={el.label}
                        value={el.value}
                        isChecked={el.value === cardsCount}
                        onChange={this.inputChange}
                      />
                    </div>
                  ))}
                </div>
              </div>

              <div className='session-other-options__option'>
                <p className='session-other-options__option-label'>
                  <I18n tKey='session.options_panel.options_label' />
                </p>
                <CheckBox
                  label='session.options_panel.check_box_put_in_the_end'
                  onChange={this.toggleBox}
                  isChecked={backInTheEnd}
                  name='backInTheEnd'
                  value={!backInTheEnd}
                />
              </div>

              {/* <div className='session-other-options__option'> */}
              {/*  <CheckBox */}
              {/*    label='session.options_panel.check_box_enable_video' */}
              {/*    onChange={this.toggleBox} */}
              {/*    isChecked={withVideo} */}
              {/*    name='withVideo' */}
              {/*    value={!withVideo} */}
              {/*  /> */}
              {/* </div> */}

              {/* <div className='session-other-options__option'> */}
              {/*  <CheckBox */}
              {/*    label='session.options_panel.check_box_additional_card' */}
              {/*    onChange={this.toggleBox} */}
              {/*    isChecked={pickExtraCard} */}
              {/*    name='pickExtraCard' */}
              {/*    value={!pickExtraCard} */}
              {/*  /> */}
              {/* </div> */}
            </div>
          )}
          <div>
            <Button
              label='constructor.header'
              lgSize
              BtnPic={PlusIcon}
              altTheme
              onClick={this.createSession}
              disabled={sessionIsCreating || !defaultDeck || !sessionName}
            />
          </div>
        </div>
      </div>
    );
  }
}
