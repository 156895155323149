import {RouterStore} from 'mobx-react-router';
import ReactDOM from 'react-dom';
import React from 'react';

import {App} from './App';
import * as serviceWorker from './serviceWorker';
import {RootStore} from './stores/RootStore';

const routingStore = new RouterStore();
const rootStore = new RootStore();

const store = {
  routing: routingStore,
  rootStore,
};

ReactDOM.render(<App store={store} />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
