import React, {Component, Fragment} from 'react';
import classNames from 'classnames';
import {inject} from 'mobx-react';

import Moveable from 'react-moveable';
import closeIcon from '../../../img/close.svg';
import eyeIcon from '../../../img/eye.svg';
import turnIcon from '../../../img/perevern.png';

import {RDR_BLOCK, WS_MSGS} from '../../../config/const';
import {convertOffsetToPercents, convertPercentsToOffset, getContainerSize} from '../../../utils/utils';
import {CardPreview} from '../../modals/cardPreview/CardPreview';

@inject('rootStore')
export default class RdrCard extends Component {
  state = {
    fieldSize: {
      // default sizes, are arbitrary
      w: 600,
      h: 600,
    },
    selected: false,
  };

  frame = {
    translate: [0, 0],
    rotate: 0,
  };

  cardContainer = React.createRef();

  cardRef = React.createRef();

  widgetRef = React.createRef();

  geometryIsChanging = false;

  componentDidMount() {
    this.setParentSize();
    window.addEventListener('resize', this.setParentSize);
    document.querySelector(`#${RDR_BLOCK}`).addEventListener('click', this.listenClick);
  }

  componentDidUpdate() {
    const newTranslate = [0, 0];
    const {rotate = 0, translate} = this.props;
    const {fieldSize} = this.state;
    // changes local variables for widget, it prevents glitches like bounds and others
    if ((translate || []).length) {
      newTranslate[0] = Number(convertPercentsToOffset(null, translate[0], false, fieldSize.w));
      newTranslate[1] = Number(convertPercentsToOffset(null, translate[1], true, fieldSize.h));
    }
    this.frame = {
      rotate,
      translate: newTranslate,
    };
    this.widgetRef.current.updateRect(); // updates widget border position
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setParentSize);
    document.querySelector(`#${RDR_BLOCK}`).removeEventListener('click', this.listenClick);
  }

  listenClick = (ev) => {
    if (!this.cardContainer.current.contains(ev.target) && !this.geometryIsChanging) {
      this.setState({selected: false});
    }
  };

  setParentSize = () => {
    const {w, h} = getContainerSize(RDR_BLOCK);
    this.setState({
      fieldSize: {
        w,
        h,
      },
    });
  };

  removeCard = () => {
    const {deckId, id, rootStore} = this.props;
    rootStore.sessionWsStore.sendMsg(WS_MSGS.RETURN_INTO_DECK, {deckId, id});
  };

  onGeometryChangeStart = () => {
    this.geometryIsChanging = true;
  };

  onGeometryChangeEnd = () => {
    setTimeout(() => {
      this.geometryIsChanging = false;
    });
  };

  onResizeStart = (e) => {
    // TODO block current card for another user, as variant send active card via ws and block it
    e.setOrigin(['%', '%']);
    e.dragStart && e.dragStart.set(this.frame.translate);
  };

  onRotateStart = (e) => {
    // TODO block current card for another user, as variant send active card via ws and block it
    e.set(this.frame.rotate);
  };

  onDragStart = (e) => {
    // TODO block current card for another user, as variant send active card via ws and block it
    e.set(this.frame.translate);
  };

  onResize = (e) => {
    this.onGeometryChangeStart();
    const {drag, target, width, height} = e;
    const {beforeTranslate} = drag;
    this.frame.translate = beforeTranslate;
    target.style.width = `${width}px`;
    target.style.height = `${height}px`;
    target.style.transform = `translate(${beforeTranslate[0]}px, ${beforeTranslate[1]}px) rotate(${this.frame.rotate}deg)`;
  };

  onDrag = (e) => {
    this.onGeometryChangeStart();
    const {beforeTranslate} = e;
    this.frame.translate = beforeTranslate;
    e.target.style.transform = `translate(${beforeTranslate[0]}px, ${beforeTranslate[1]}px) rotate(${this.frame.rotate}deg)`;
  };

  onRotate = (e) => {
    this.onGeometryChangeStart();
    const {target, beforeRotate} = e;
    this.frame.rotate = beforeRotate;
    target.style.transform = `translate(${this.frame.translate[0]}px, ${this.frame.translate[1]}px) rotate(${beforeRotate}deg)`;
  };

  onDragEnd = (e) => {
    const {lastEvent} = e;
    if (lastEvent) {
      const {sendMsg} = this.props.rootStore.sessionWsStore;
      const {id} = this.props;
      const {fieldSize} = this.state;
      const {beforeTranslate} = lastEvent;
      this.frame.translate = beforeTranslate;
      const msgData = {
        cardId: id,
        translate: [
          convertOffsetToPercents(null, beforeTranslate[0], false, fieldSize.w),
          convertOffsetToPercents(null, beforeTranslate[1], true, fieldSize.h),
        ],
      };
      sendMsg(WS_MSGS.CARD_DRAGGED, msgData);
      this.onGeometryChangeEnd();
    }
  };

  onRotateEnd = (e) => {
    const {lastEvent} = e;
    if (lastEvent) {
      const {sendMsg} = this.props.rootStore.sessionWsStore;
      const {id} = this.props;
      this.frame.rotate = lastEvent.beforeRotate;
      const msgData = {
        cardId: id,
        rotate: lastEvent.beforeRotate,
      };
      sendMsg(WS_MSGS.CARD_ROTATED, msgData);
      this.onGeometryChangeEnd();
    }
  };

  onResizeEnd = (e) => {
    // console.log(e)
    const {lastEvent} = e;
    if (lastEvent) {
      const {sendMsg} = this.props.rootStore.sessionWsStore;
      const {id} = this.props;
      const {fieldSize} = this.state;
      const {height, beforeTranslate} = lastEvent.drag;
      this.frame.translate = beforeTranslate;
      // console.log(height, this.props.originalHeight, height * 100 / this.props.originalHeight)
      const msgData = {
        cardId: id,
        scale: (height * 100) / this.props.originalHeight / 100,
        translate: [
          convertOffsetToPercents(null, beforeTranslate[0], false, fieldSize.w),
          convertOffsetToPercents(null, beforeTranslate[1], true, fieldSize.h),
        ],
      };
      sendMsg(WS_MSGS.CARD_RESIZED, msgData);
      this.onGeometryChangeEnd();
    }
  };

  toggleRdr = () => {
    if (!this.geometryIsChanging) {
      if (!this.state.selected) {
        const {sendMsg} = this.props.rootStore.sessionWsStore;
        const {id, maxIndex} = this.props;
        this.cardContainer.current.style.zIndex = maxIndex + 1;
        sendMsg(WS_MSGS.CHANGE_INDEX, {cardId: id});
      }
      this.setState({selected: !this.state.selected});
    }
  };

  toggleCardView = (ev) => {
    const {deckId, id} = this.props;
    const {sendMsg} = this.props.rootStore.sessionWsStore;
    sendMsg(WS_MSGS.TOGGLE_ACTIVE_CARD, {deckId, cardId: id});
    this.controlBtnClicked(ev);
  };

  openCardPreview = () => {
    this.props.rootStore.modalStore.open(CardPreview, null, null, {url: this.props.cardFaceImg});
  };

  controlBtnClicked = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
  };

  render() {
    const {
      cardFaceImg,
      isFlipped,
      cardShirt,
      originalWidth,
      originalHeight,
      scale = 1,
      translate = [0, 0],
      rotate = 0,
      index,
    } = this.props;

    const width = originalWidth * scale;
    const height = originalHeight * scale;
    const {fieldSize, selected} = this.state;
    // console.log(scale, `${convertPercentsToOffset(null, width, false, fieldSize.w)}px`);

    const btnClass = {
      'card-scene__btn--disabled': !selected,
      'card-scene__btn--enabled': selected,
    };
    const wrapperStyle = {
      width: `${width}px`, // `${convertPercentsToOffset(null, width, false, fieldSize.w)}px`,
      height: `${height}px`, // `${convertPercentsToOffset(null, height, true, fieldSize.h)}px`,
      transform: `translate(${convertPercentsToOffset(
        null,
        translate[0],
        false,
        fieldSize.w
      )}px,${convertPercentsToOffset(null, translate[1], true, fieldSize.h)}px) rotate(${rotate}deg)`,
      zIndex: index,
    };
    const blockClassParams = {
      'card--flipped': isFlipped,
    };
    return (
      <>
        <div
          ref={this.cardContainer}
          onClick={this.toggleRdr}
          className='card-scene card-scene--abs'
          style={wrapperStyle}
        >
          <div ref={this.cardRef} className={classNames('card', blockClassParams)}>
            <div
              style={
                cardShirt ? {backgroundImage: `url(${cardShirt})`, backgroundSize: 'cover'} : {backgroundColor: 'black'}
              }
              className='card__img card__img--front'
            />
            <div
              style={{backgroundImage: `url(${cardFaceImg})`, backgroundSize: 'contain'}}
              className='card__img card__img--back'
            />
          </div>
          <button
            onClick={this.removeCard}
            type='button'
            className={classNames('card-scene__btn card-scene__btn--right', btnClass)}
          >
            <img className='card-scene__btn-pic' src={closeIcon} alt='ic' />
          </button>
          {isFlipped && (
            <button
              onClick={this.openCardPreview}
              type='button'
              className={classNames('card-scene__btn card-scene__btn--bot', btnClass)}
            >
              <img className='card-scene__btn-pic' src={eyeIcon} alt='ic' />
            </button>
          )}
          <button
            onClick={this.toggleCardView}
            type='button'
            className={classNames('card-scene__btn card-scene__btn--left', btnClass)}
          >
            <img className='card-scene__btn-pic' src={turnIcon} alt='ic' />
          </button>
        </div>
        <Moveable
          ref={this.widgetRef}
          className={classNames('rnd-card-controls', {'rnd-card-controls--inactive': !selected})}
          target={this.cardContainer.current}
          origin={false}
          edge={false}
          resizable={selected}
          draggable={selected}
          rotatable={selected}
          keepRatio
          snappable
          bounds={{left: 0, top: 0, right: fieldSize.w, bottom: fieldSize.h}}
          renderDirections={['nw', 'ne', 'sw', 'se']}
          onResizeStart={this.onResizeStart}
          onResize={this.onResize}
          onResizeEnd={this.onResizeEnd}
          onDragStart={this.onDragStart}
          onDrag={this.onDrag}
          onDragEnd={this.onDragEnd}
          onRotateStart={this.onRotateStart}
          onRotate={this.onRotate}
          onRotateEnd={this.onRotateEnd}
        />
      </>
    );
  }
}
