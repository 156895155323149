import {action, computed, observable} from 'mobx';

export class ModalStore {
  @observable modals = {};

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @computed get activeModal() {
    return this.modals.cmp;
  }

  @computed get modalParams() {
    return this.modals.data;
  }

  @action
  open(modalCmp, onOk, onCancel, data) {
    if (this.modals.cmp) {
      this.close();
    } else {
      this.modals = {cmp: modalCmp, onOk, onCancel, data};
    }
  }

  @action
  close() {
    this.modals = {};
  }
}
