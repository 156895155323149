import {observable, action} from 'mobx';

import {UserStore} from './UserStore';
import {ThemeStore} from './ThemeStore';
import {ModalStore} from './ModalStore';
import {SessionWsStore} from './SessionWsStore';
import {SidePanelStore} from './SidePanelStore';
import {AllSessionsStore} from './AllSessionsStore';

export class RootStore {
  @observable isLoaded = false;

  @observable isAppError = false;

  constructor() {
    this.preloaderFlags = {
      userStore: false,
    };

    this.userStore = new UserStore(this, this.waitLoader('userStore'));
    this.modalStore = new ModalStore(this);
    this.sidePanelStore = new SidePanelStore(this);
    this.sessionWsStore = new SessionWsStore(this);
    this.allSessionsStore = new AllSessionsStore(this);
    this.themeStore = new ThemeStore(this);
  }

  @action waitLoader = (flagName) => (status) => {
    if (status) {
      this.preloaderFlags[flagName] = true;
      if (Object.values(this.preloaderFlags).filter((f) => !f).length === 0) {
        this.toggleLoadedState();
      }
    } else {
      this.toggleLoadedState();
      this.toggleError();
    }
  };

  @action toggleLoadedState = () => {
    this.isLoaded = !this.isLoaded;
  };

  @action toggleError = () => {
    this.isAppError = !this.isAppError;
  };
}
