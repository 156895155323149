import dictionary from '../config/dictionary.yml';

const LANG = 'ru';

export const translate = (key) => {
  if (typeof key !== 'string') {
    return key;
  }

  const path = key.split('.');
  let level = dictionary;
  // eslint-disable-next-line no-restricted-syntax
  for (const bk of path) {
    if (!level[bk]) {
      level = null;
      break;
    }
    level = level[bk];
  }

  if (level && typeof level === 'object') {
    level = level[LANG];
  }

  // console.log(level, key)
  return level || key;
};
