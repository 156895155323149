import React, {Component} from 'react';

import {PANELS} from '../../../stores/SidePanelStore';
import SidePanel from '../../helpers/sidePanel/sidePanel';

import {CardsPanel} from './CardsPanel';
import {SessionSettings} from './SessionSettings';

export class SidePanels extends Component {
  render() {
    return (
      <>
        <SidePanel
          panelName={PANELS[0].value}
          headerPic={PANELS[0].picUrl}
          headerTitle='session.decks_panel.deck_label'
        >
          <CardsPanel />
        </SidePanel>
        <SidePanel
          panelName={PANELS[1].value}
          headerPic={PANELS[1].picUrl}
          headerTitle='session.options_panel.options_label'
        >
          <SessionSettings />
        </SidePanel>
      </>
    );
  }
}
