import {inject} from 'mobx-react';
import React, {Component} from 'react';

import {Button} from '../../helpers/button/Button';

import {ReactComponent as PlusIcon} from '../../../img/plus.svg';
import {ReactComponent as MinusIcon} from '../../../img/minus.svg';
import {ReactComponent as TurnIcon} from '../../../img/turn.svg';
import {ReactComponent as ShuffleIcon} from '../../../img/shuffle.svg';
import turnIcon from '../../../img/perevern.png';

import {WS_MSGS} from '../../../config/const';

@inject('rootStore')
export class DeckPanelControls extends Component {
  mixCards = () => {
    const {sendMsg} = this.props.rootStore.sessionWsStore;
    sendMsg(WS_MSGS.MIX_DECKS, null);
  };

  rotateDeck = () => {
    const {deck} = this.props;
    const {sendMsg} = this.props.rootStore.sessionWsStore;
    sendMsg(WS_MSGS.turnOver, {deck: deck.folder});
  };

  toggleDeckView = () => {
    const {deck} = this.props;
    const {sendMsg} = this.props.rootStore.sessionWsStore;
    sendMsg(WS_MSGS.TOGGLE_VIEW, {deckName: deck.folder});
  };

  render() {
    const {changeScale} = this.props.rootStore.sessionWsStore;
    const {isAdmin, deck} = this.props;
    return (
      <div className='btns-panel'>
        <div className='btns-panel__item'>
          <Button
            onClick={changeScale.bind(changeScale, false)}
            BtnPic={MinusIcon}
            label='session.decks_panel.btn.zoom_out'
            smSize
          />
        </div>
        <div className='btns-panel__item'>
          <Button
            onClick={changeScale.bind(changeScale, true)}
            BtnPic={PlusIcon}
            label='session.decks_panel.btn.zoom_in'
            smSize
          />
        </div>
        {isAdmin && deck && (
          <>
            <div className='btns-panel__item'>
              <Button onClick={this.mixCards} BtnPic={ShuffleIcon} label='session.decks_panel.btn.shuffle' smSize />
            </div>
            <div className='btns-panel__item'>
              <Button onClick={this.toggleDeckView} BtnPic={TurnIcon} label='session.decks_panel.btn.turn' smSize />
            </div>
            <div className='btns-panel__item'>
              <Button onClick={this.rotateDeck} btnPic={turnIcon} label='Перевернуть' smSize />
            </div>
          </>
        )}
      </div>
    );
  }
}
