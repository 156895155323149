import {action, observable} from 'mobx';
import {STORAGE_KEY} from '../config/const';
import {checkToken, makeLogin, register, resetPassword} from '../utils/requester';

export class UserStore {
  @observable user = {isAdmin: false};

  constructor(rootStore, cb) {
    this.rootStore = rootStore;
    this.checkUser(cb);
  }

  @action
  login = (pass, name) => {
    return makeLogin(pass, name).then((r) => {
      if (!r.isError) {
        this.saveUser(r.data);
        this.user.isAdmin = true;
      }
      return r;
    });
  };

  @action
  register = (email, name, password) => {
    return register(email, name, password).then((r) => {
      if (!r.isError) {
        this.saveUser(r.data);
        this.user.isAdmin = true;
      }
      return r;
    });
  };

  resetPassword = (email) => {
    return resetPassword(email).then((r) => {
      return r;
    });
  };

  @action
  logout = () => {
    localStorage.removeItem(STORAGE_KEY);
    this.user.isAdmin = false;
  };

  @action
  checkToken = (cb) => {
    checkToken().then((resp) => {
      resp.isError && this.removeUser();
      this.user.isAdmin = !resp.isError;
      cb(true);
    });
  };

  @action
  saveUser = (data) => {
    localStorage.setItem(STORAGE_KEY, data.token);
  };

  @action
  removeUser = () => {
    localStorage.removeItem(STORAGE_KEY);
    this.user.isAdmin = false;
  };

  @action
  checkUser = (cb) => {
    const savedUser = localStorage.getItem(STORAGE_KEY);
    savedUser ? this.checkToken(cb) : setTimeout(cb.bind(this, true), 0);
  };
}
