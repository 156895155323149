import React, {Component} from 'react';
import classNames from 'classnames';
import {I18n} from '../i18n/I18n';

export class Button extends Component {
  render() {
    const {
      label,
      BtnPic,
      btnPic,
      disabled,
      onClick,
      altTheme,
      reverse,
      smSize,
      lgSize,
      changeStroke = true,
      children,
      boldFont,
      fillContainer,
    } = this.props;
    const blockClassParams = {
      'app-btn--reverse': reverse,
      'app-btn--sm': smSize,
      'app-btn--md': !smSize && !lgSize,
      'app-btn--lg': lgSize,
      'app-btn--default': !altTheme,
      'app-btn--alt': altTheme,
      'app-btn--fill-container': fillContainer,
    };
    const picClassParams = {
      'app-btn__pic--default-fill': !altTheme && !changeStroke,
      'app-btn__pic--default-stroke': !altTheme && changeStroke,
      'app-btn__pic--alt-fill': altTheme && !changeStroke,
      'app-btn__pic--alt-stroke': altTheme && changeStroke,
      'app-btn__pic--offset': !!label,
      'app-btn__pic--sm': smSize,
      'app-btn__pic--md': !smSize && !lgSize,
      'app-btn__pic--lg': lgSize,
    };
    const labelClassParams = {
      'app-btn__label--default': !altTheme,
      'app-btn__label--alt': altTheme,
      'app-btn__label--bold': boldFont,
    };
    return (
      <button type='button' disabled={disabled} className={classNames('app-btn', blockClassParams)} onClick={onClick}>
        {BtnPic && <BtnPic className={classNames('app-btn__pic', picClassParams)} alt='btn-pic' />}
        {btnPic && <img className={classNames('app-btn__pic', picClassParams)} alt='btn-pic' src={btnPic} />}
        {label && <I18n className={classNames('app-btn__label', labelClassParams)} tKey={label} />}
        {children}
      </button>
    );
  }
}
