import React, {Component} from 'react';
import classNames from 'classnames';

import {translate} from '../../../utils/translater';

export class I18n extends Component {
  render() {
    return (
      <span onClick={this.props.onClick} className={classNames(this.props.className)}>
        {translate(this.props.tKey)}
      </span>
    );
  }
}
