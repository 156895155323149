import {action, observable} from 'mobx';
import {BG_STORAGE_KEY} from '../config/const';

export class ThemeStore {
  @observable boardBg = localStorage.getItem(BG_STORAGE_KEY);

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @action setBg = (url) => {
    if (url) {
      localStorage.setItem(BG_STORAGE_KEY, url);
    } else {
      localStorage.removeItem(BG_STORAGE_KEY);
    }
    this.boardBg = url;
  };
}
