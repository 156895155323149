import React, {Component} from 'react';
import classNames from 'classnames';
import Select, {components} from 'react-select';
import {I18n} from '../i18n/I18n';

import {translate} from '../../../utils/translater';
import {ReactComponent as ArrowIcon} from '../../../img/dropdow_arrow.svg';

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <ArrowIcon />
    </components.DropdownIndicator>
  );
};
export class Dropdown extends Component {
  handleChange = (selectedOption) => {
    this.props.onChange(selectedOption);
  };

  render() {
    const {label, lgSize, smSize, options, placeholder, formatOptionLabel, value, defaultValue} = this.props;
    const labelClassParams = {
      'select-wrapper__label--lg-pd': lgSize,
      'select-wrapper__label--md-pd': !lgSize && !smSize,
      'select-wrapper__label--sm-pd': smSize,
    };
    return (
      <div className='select-wrapper'>
        {label && (
          <div className={classNames('select-wrapper__label', labelClassParams)}>
            <I18n tKey={label} />
          </div>
        )}
        <Select
          className='select-container'
          value={value}
          defaultValue={defaultValue}
          onChange={this.handleChange}
          options={options}
          placeholder={translate(placeholder)}
          isClearable={false}
          isSearchable={false}
          classNamePrefix='select'
          formatOptionLabel={formatOptionLabel}
          width='100%'
          components={{DropdownIndicator}}
        />
      </div>
    );
  }
}
