import React, {Component} from 'react';
import classNames from 'classnames';
import {I18n} from '../i18n/I18n';

import {ReactComponent as EyeIcon} from '../../../img/eye-open.svg';
import {ReactComponent as EyeIconLocked} from '../../../img/eye-locked.svg';
import {ReactComponent as IconClear} from '../../../img/icon-close.svg';

import {translate} from '../../../utils/translater';

export class Input extends Component {
  inputRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      inputType: props.isPassword ? 'password' : 'text',
    };
  }

  componentDidMount() {
    this.props.autoFocus && this.inputRef.current.focus();
  }

  switchVisibility = () => {
    let nextState = null;
    this.state.inputType === 'password' ? (nextState = 'text') : (nextState = 'password');
    this.setState({inputType: nextState});
  };

  clearValue = () => {
    const {onChange} = this.props;
    onChange && onChange(this.props.name, '');
  };

  onChange = (ev) => {
    const {onChange} = this.props;
    let {value} = ev.target;
    if (value.length <= 1) {
      value = ev.target.value.trim();
    }
    onChange && onChange(ev.target.name, value);
  };

  render() {
    const {
      showEye,
      autocomplete = 'off',
      allowClear,
      name,
      onKeyPress,
      label,
      value,
      onKeyDown,
      onClick,
      error,
      placeholder,
      smSize,
      lgSize,
      readOnly,
    } = this.props;
    const {inputType} = this.state;
    const canRenderCrossIcon = value && allowClear;
    const PassIcon = inputType === 'password' ? EyeIconLocked : EyeIcon;
    const labelClassParams = {
      'input-wrapper__label--lg-pd': lgSize,
      'input-wrapper__label--md-pd': !lgSize && !smSize,
      'input-wrapper__label--sm-pd': smSize,
    };

    const inputClassParams = {
      'input-container__input--lg': lgSize,
      'input-container__input--md': !lgSize && !smSize,
      'input-container__input--sm': smSize,
      'input-container__input--with-eye': showEye,
      'input-container__input--with-clear-icon': canRenderCrossIcon,
    };

    return (
      <div className='input-wrapper'>
        {label && (
          <div className={classNames('input-wrapper__label', labelClassParams)}>
            <I18n tKey={label} />
          </div>
        )}
        <label className='input-container'>
          <input
            onChange={this.onChange}
            onKeyPress={onKeyPress}
            onKeyDown={onKeyDown}
            ref={this.inputRef}
            placeholder={translate(placeholder)}
            onClick={onClick}
            value={value}
            type={inputType}
            name={name}
            autoComplete={autocomplete}
            readOnly={readOnly}
            className={classNames('input-container__input', inputClassParams)}
          />
          {canRenderCrossIcon && <IconClear onClick={this.clearValue} className='input-container__clear-icon' />}
          {showEye && <PassIcon onClick={this.switchVisibility} className='input-container__eye-img' />}
        </label>
        {error && (
          <div className='input-wrapper__error'>
            <I18n tKey='errors.warning' />
          </div>
        )}
      </div>
    );
  }
}
