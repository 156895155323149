export const STORAGE_KEY = 'CARDS_ADMIN';
export const BG_STORAGE_KEY = 'CARDS_BORD_BG';
export const INIT_CARD_SIZE = {
  W: 120,
};

export const RDR_BLOCK = 'active-cards-field';

export const WS_MSGS = {
  REMOVE_ACTIVE_SELECTIONS: 'removeActiveSelections',
  NEW_ACTIVE_CARDS_ORDER: 'newActiveCardsOrder', // todo now isn`t used
  UPDATE_MAIN_SETTINGS: 'updateMainSettings',
  SET_SELECTION_SIZE: 'setSelectionsSize', // todo now isn`t used, size is seted via updateMainSettings
  ANIMATED_SELECTION: 'animatedSelection',
  RETURN_INTO_DECK: 'returnIntoDeck',
  UPDATE_SETTINGS: 'updateSettings',
  EMPTY_SESSION: 'emptySession',
  WRONG_TOKEN: 'wrongToken',
  ADD_EXTRA_CARD: 'addExtraCard', // todo now isn`t used
  TOGGLE_TRIAL: 'toggleTrial',
  TOGGLE_ACTIVE_CARD: 'toggleActiveCard',
  CARD_DRAGGED: 'cardDragged',
  CARD_RESIZED: 'cardResized',
  CARD_ROTATED: 'cardRotated',
  TOGGLE_VIEW: 'toggleView',
  MIX_ACTIVE: 'mixActive', // todo now isn`t used, maybe cards will be mixed on new bord
  CONNECTED: 'connected',
  CHANGE_INDEX: 'changeIndex',
  MIX_DECKS: 'mixDecks',
  turnOver: 'turnOver',
  ADD_DECK: 'addADeck',
  IS_ALIVE: 'isAlive',
};

export const DATE_FORMATS = {SESSION_DATE: 'MM/DD/YY', TIME: 'HH:mm', DURATION: 'mm:ss'};
