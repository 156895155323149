import React, {Component} from 'react';
import classNames from 'classnames';

export class BgSelectorItem extends Component {
  setBg = () => {
    this.props.setBg && this.props.setBg(this.props.bgImg);
  };

  render() {
    const {isChecked, bgImg} = this.props;
    const bgClassParams = {
      'bg-selector--checked': isChecked,
      'bg-selector--shadow': bgImg === null,
    };
    return (
      <div
        onClick={this.setBg}
        style={bgImg ? {backgroundImage: `url(${bgImg})`} : {}}
        className={classNames('bg-selector bg-selector--default-size', bgClassParams)}
      />
    );
  }
}
