import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {inject} from 'mobx-react';

import {CopyField} from '../../helpers/copyField/CopyField';
import {I18n} from '../../helpers/i18n/I18n';

@inject('rootStore')
export class TableSessionTools extends Component {
  remove = (code) => {
    this.props.rootStore.allSessionsStore.deleteSession(code);
  };

  render() {
    const {data} = this.props;
    return (
      <div className='tools-link'>
        <Link to={data.url} className='tools-link__item'>
          <I18n tKey='common.open' />
        </Link>
        <div className='tools-link__item'>
          <CopyField
            copyTxtValue={`${window.location.protocol}//${window.location.host}${data.url}`}
            txt='common.copy_link'
            cbTxt='common.done'
          />
        </div>
        <I18n onClick={this.remove.bind(this, data.code)} className='tools-link__item' tKey='common.delete' />
      </div>
    );
  }
}
