import React, {Component} from 'react';
import classNames from 'classnames';
import {I18n} from '../i18n/I18n';

export class RadioButton extends Component {
  onChange = () => {
    const {name, value} = this.props;
    this.props.onChange && this.props.onChange(name, value);
  };

  render() {
    const {name, label, isChecked, value} = this.props;
    const btnClassParams = {
      'radio-btn__fake--checked': isChecked,
      'radio-btn__fake--offset': !!label,
    };
    return (
      <label className='radio-btn'>
        <input
          className='radio-btn__input'
          type='radio'
          onChange={this.onChange}
          value={value}
          name={name}
          checked={isChecked}
        />
        <span className={classNames('radio-btn__fake', btnClassParams)} />
        {label && <I18n tKey={label} className='radio-btn__label' />}
      </label>
    );
  }
}
