import React, {Component} from 'react';
import classNames from 'classnames';
import {INIT_CARD_SIZE} from '../../../config/const';

export class Card extends Component {
  cardContainer = React.createRef();

  onDoubleClick = () => {
    const {onDoubleClick, isTrialMode, animationIsActive} = this.props;
    if (onDoubleClick && !animationIsActive) {
      if (isTrialMode) {
        this.cardContainer.current.classList.add('card-scene--transparent');
      }
      onDoubleClick();
    }
  };

  render() {
    const {
      isFlipped,
      cardFaceImg,
      cardIsHorizontal,
      scale = 1,
      animationIsActive,
      deckIsHorizontal,
      cardShirt,
      originalHeight,
      originalWidth,
    } = this.props;
    let cardRatio = originalWidth / originalHeight;

    if (cardIsHorizontal) {
      cardRatio = originalHeight / originalWidth;
    }

    let width = INIT_CARD_SIZE.W;
    let height = Math.floor(INIT_CARD_SIZE.W / cardRatio);
    if (deckIsHorizontal !== undefined) {
      if (deckIsHorizontal) {
        width = Math.floor(INIT_CARD_SIZE.W / cardRatio);
        height = INIT_CARD_SIZE.W;
      }
    }

    const wrapperClassParams = {
      'card-scene--transparent': animationIsActive,
    };
    const blockClassParams = {
      'card--flipped': isFlipped,
    };
    return (
      <div
        ref={this.cardContainer}
        className={classNames('card-scene', wrapperClassParams)}
        style={{width: `${width * scale}px`, height: `${height * scale}px`}}
      >
        <div className={classNames('card', blockClassParams)} onDoubleClick={this.onDoubleClick}>
          <div style={cardShirt ? {backgroundImage: `url(${cardShirt})`} : {}} className='card__img card__img--front' />
          <div
            style={{
              backgroundImage: `url(${cardFaceImg})`,
              backgroundSize: 'contain',
            }}
            className='card__img card__img--back'
          />
        </div>
      </div>
    );
  }
}
